import ChartLayout from "../../../components/ChartLayout";
import MultiSpeedChart from "./MultiSpeedChart";
import MultiSpeedForm from "./MultiSpeedForm";
import useSpuPoints from "../../../stores/spuPoints";
import EmptyChart from "../components/EmptyChart";
import Loading from "../components/Loading";

const MultiSpeed = () => {
  const { name, info, dataset, multiSpeed, loading } = useSpuPoints();

  if (loading) return <Loading />;

  if (!name || !dataset) return <EmptyChart />;

  return (
    <ChartLayout
      chart={
        <MultiSpeedChart
          key={name}
          name={name}
          info={info}
          dataset={dataset}
          multiSpeed={multiSpeed}
        />
      }
      form={<MultiSpeedForm />}
    />
  );
};

export default MultiSpeed;
