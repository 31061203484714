import { useState } from "react";
import { useTranslation } from "react-i18next";

const useModal = (
  submitHook?: () => void,
  cancelHook?: () => void,
  showHook?: () => void,
  hideHook?: () => void
) => {
  const { t } = useTranslation();
  const okText = t("confirm.ok");
  const cancelText = t("confirm.cancel");

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const show = () => {
    if (showHook) showHook();
    setVisible(true);
  };
  const hide = () => {
    if (hideHook) hideHook();
    setVisible(false);
  };

  const onOk = async () => {
    setLoading(true);
    if (submitHook) await submitHook();
    setLoading(false);
    hide();
  };

  const onCancel = () => {
    if (cancelHook) cancelHook();
    hide();
  };

  return {
    onOk,
    onCancel,
    confirmLoading: loading,
    visible,
    show,
    hide,
    okText,
    cancelText,
  };
};

export default useModal;
