import { AxiosResponse } from "axios";
import create from "zustand";
import modelServices, { AttrData } from "../services/model";

export interface Name {
  id?: string;
  value: string;
  lang: string;
}

interface AttributeBaseState {
  id?: number;
  order: number;
  name: Name[];
  classifyId?: any;
}

export interface SubAttributeState extends AttributeBaseState {
  attrId?: number;
  src: number;
  path: string;
}

export interface AttributeState extends AttributeBaseState {
  modelId?: number;
  type: 0 | 1; // 0: text, 1: image
  list: SubAttributeState[];
}

interface ModelInitState {
  id: number;
  name: string;
  loading: boolean;
  attributes: AttributeState[];
  classify: any[];
  specArr: any[];
  cusSpecArr: any[];
}

interface ModelState extends ModelInitState {
  setName: (name: string) => void;
  resetAll: () => void;
  appendAttribute: (attr: AttributeState) => void;
  removeAttribute: (index: number) => void;
  updateAttribute: (index: number, attr: AttributeState) => void;
  appendSubAttribute: (index: number, attr: SubAttributeState) => void;
  removeSubAttribute: (rowIndex: number, colIndex: number) => void;
  updateSubAttribute: (
    rowIndex: number,
    colIndex: number,
    attr: SubAttributeState
  ) => void;
  appendSpec: (spec: any) => void;
  updateSpec: (index: number, spec: any) => void;
  removeSpec: (index: number) => void;
  removeSpecList: (parentIndex: number, index: number) => void;
  appendCusSpec: (spec: any) => void;
  updateCusSpec: (index: number, spec: any) => void;
  removeCusSpec: (index: number) => void;
  fetchModel: (id: number, loading?: boolean, callback?: Function) => void;
  updateClassifyAttr: (data: any) => void;
  updateModel: (type: any) => void;
  createModel: () => Promise<AxiosResponse<any>>;
}

const parseAttrBase = (attr: any): AttributeBaseState => {
  return {
    id: attr.id,
    order: attr.order_index,
    name: attr.name_lang,
  };
};

const parseSubAttr = (attr: any): SubAttributeState => {
  return {
    ...parseAttrBase(attr),
    attrId: attr.attr_id,
    src: attr.src,
    path: attr.path,
  };
};

const parseAttr = (attrs: any[]): AttributeState[] => {
  return attrs.map((attr) => ({
    ...parseAttrBase(attr),
    classifyId: attr.classify_id,
    modelId: attr.spu_model,
    type: attr.type,
    list: attr.list.map((subAttr: any) => parseSubAttr(subAttr)),
  }));
};

const subAttrToRequestData = (attr: SubAttributeState) => {
  let newAttr: any = {
    path: attr.path,
    src: attr.src,
    order_index: attr.order,
    name_lang: attr.name
  };

  if (attr.attrId) {
    newAttr = {
      ...newAttr,
      attr_id: attr.attrId
    };
  }

  if (attr.id) {
    newAttr = {
      ...newAttr,
      id: attr.id
    };
  }

  return newAttr;
};

const attributesToRequestData = (attributes: AttributeState[]): AttrData[] => {
  return attributes.map((attr: any) => {
    let newAttr: any = {
      order_index: attr.order,
      name_lang: attr.name,
      type: attr.type,
      list: attr.list.map((item: any) => subAttrToRequestData(item)),
    };

    if (attr.id) {
      newAttr = {
        ...newAttr,
        id: attr.id,
        spu_model: attr.modelId,
      };
    }

    return newAttr;
  });
};

const initialState: ModelInitState = {
  id: 0,
  name: "",
  loading: false,
  attributes: [], // 属性
  classify: [],
  specArr: [], // 规格
  cusSpecArr: [] // 非标规格
};

const useModel = create<ModelState>((set, get) => ({
  ...initialState,
  setName: (name: string) => set({ name }),
  resetAll: () => set({ ...initialState }),
  appendAttribute: (newAttribute: AttributeState) => {
    const newAttributeName = newAttribute.name[0].value;
    const attributeNameList = get().attributes.map(
      (item) => item.name[0].value
    );

    if (attributeNameList.includes(newAttributeName)) {
      return;
    }

    return set(({ attributes }) => ({
      attributes: [...attributes, newAttribute],
    }));
  },
  removeAttribute: (index: number) =>
    set(({ attributes }) => ({
      attributes: attributes.filter((_, i) => i !== index),
    })),
  updateAttribute(index: number, newAttribute: AttributeState) {
    const newAttributes = get().attributes.map((attr, i) => {
      if (i === index) {
        return newAttribute;
      }
      
      return attr;
    });

    set({ attributes: newAttributes });
  },
  appendSubAttribute: (index: number, attr: SubAttributeState) => {
    const newAttributes = get().attributes.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          list: [...item.list, attr],
        };
      }
      return item;
    });

    set({ attributes: newAttributes });
  },
  removeSubAttribute: (rowIndex: number, colIndex: number) =>
    set(({ attributes }) => ({
      attributes: attributes.map((item, i) => {
        if (i === rowIndex) {
          return {
            ...item,
            list: item.list.filter((_, j) => j !== colIndex),
          };
        }
        return item;
      }),
    })),
  updateSubAttribute: (
    rowIndex: number,
    colIndex: number,
    attr: SubAttributeState
  ) => {
    const newAttributes = get().attributes.map((item, i) => {
      if (i === rowIndex) {
        return {
          ...item,
          list: item.list.map((subItem, j) => {
            if (j === colIndex) {
              return attr;
            }

            return subItem;
          }),
        };
      }
      return item;
    });

    set({ attributes: newAttributes });
  },
  appendSpec: (spec: any) => {
    const newSpecName = spec.name_lang[0].value;

    const specNameList = get().specArr.map(
      (item) => item.name_lang[0].value
    );

    if (specNameList.includes(newSpecName)) {
      return;
    }

    return set(({ specArr }) => ({
      specArr: [...specArr, spec],
    }));
  },
  updateSpec: (index: number, spec: any) => {
    const newSpec = get().specArr.map((v, i) => {
      if (i === index) {
        return spec;
      }
      
      return v;
    });

    set({ specArr: newSpec });
  },
  removeSpec: (index: number) => 
    set(({ specArr }) => ({
      specArr: specArr.filter((_, i) => i !== index)
    })),
  removeSpecList: (parentIndex: number, index: number) => {
    const specArr = get().specArr;

    const now = specArr[parentIndex];

    const {
      list
    } = now || {};

    const ns = list.filter((_: any, i: any) => i !== index)

    now.list = ns;

    set({
      specArr
    });
  },
  appendCusSpec: (spec: any) => {
    const newSpecName = spec.name_lang[0].value;
    const specNameList = get().cusSpecArr.map(
      (item) => item.name_lang[0].value
    );

    if (specNameList.includes(newSpecName)) {
      return;
    }

    return set(({ cusSpecArr }) => ({
      cusSpecArr: [...cusSpecArr, spec],
    }));
  },
  updateCusSpec: (index: number, spec: any) => {
    const newCusSpec = get().cusSpecArr.map((v, i) => {
      if (i === index) {
        return spec;
      }
      
      return v;
    });

    set({ cusSpecArr: newCusSpec });
  },
  removeCusSpec: (index: number) => 
    set(({ cusSpecArr }) => ({
      cusSpecArr: cusSpecArr.filter((_, i) => i !== index)
    })),
  fetchModel: async (id: number, loadind: boolean = true, callback: Function = () => {}) => {
    if (loadind) {
      set({ loading: true });
    }

    const res = await modelServices.getModel(String(id));

    const data = res.data.response;

    const {
      model,
      attrArr, // 属性
      classify,
      cusSpecArr, // 非标规格
      specArr // 规格
    } = data || {};

    if (!model && !attrArr) {
      set({
        loading: false
      });

      callback && callback(null);

      return;
    }

    const newName = model.spu_model_name;
    const newAttributes = parseAttr(attrArr);

    set({
      id,
      name: newName,
      attributes: newAttributes,
      loading: false,
      classify,
      specArr,
      cusSpecArr
    });

    callback && callback({
      specArr,
      attributes: newAttributes
    });
  },
  updateClassifyAttr: async (data: any) => {
    await modelServices.postClassifyAttr(data);
  },
  updateModel: async (type: any) => {
    const specArr = get().specArr;
    const cusSpecArr = get().cusSpecArr;

    const finalSpecArr = [...specArr, ...cusSpecArr];

    await modelServices.putModel(get().id, {
      type,
      name: get().name,
      specArr: finalSpecArr,
      attrArr: attributesToRequestData(get().attributes),
    });
  },
  createModel: async () => {
    const specArr = get().specArr;
    const cusSpecArr = get().cusSpecArr;

    const finalSpecArr = [...specArr, ...cusSpecArr];

    return await modelServices.postModel({
      name: get().name,
      specArr: finalSpecArr,
      attrArr: attributesToRequestData(get().attributes),
    });
  }
}));

export default useModel;
