import { Button, Drawer, Form } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useTranslation } from "react-i18next";
import EditableTable, { RowDataType } from "../../../components/EditableTable";
import {
  checkedRowLength,
  checkedCellLength,
} from "../../SpuChart/components/SpuForm";
import useDrawer from "../../../hooks/useDrawer";
import useLoading from "../../../hooks/useLoading";
import useSpuPoints from "../../../stores/spuPoints";

const { useForm } = Form;

const Custom = () => {
  const { t } = useTranslation();
  const { xs } = useBreakpoint();
  const [form] = useForm();
  const { dataSource, setDataSource } = useSpuPoints();

  const { visible, show, hide, setVisible } = useDrawer();

  const drawerWidth = xs ? 378 : 520;

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const { data } = values;

    setDataSource(data);
    setVisible(false);
  };

  const validateTable = (_: any, row: RowDataType[]) => {
    const isRowLengthValid = checkedRowLength(row);
    if (!isRowLengthValid)
      return Promise.reject(new Error(t("spuForm.validate.error1")));

    const isCellLengthValid = checkedCellLength(row);
    if (isCellLengthValid) return Promise.resolve();
    return Promise.reject(new Error(t("spuForm.validate.error2")));
  };

  const [onSubmit, loading] = useLoading(handleSubmit);

  return (
    <>
      <Button type="default" onClick={show}>
        {t("spuChart.tools.custom")}
      </Button>
      <Drawer
        title={t("spuChart.tools.custom")}
        onClose={hide}
        visible={visible}
        destroyOnClose
        width={drawerWidth}
        extra={
          <Button type="primary" onClick={onSubmit} loading={loading}>
            {t("spuChart.tools.customDrawer.submit")}
          </Button>
        }
      >
        <Form form={form} initialValues={{ data: dataSource }}>
          <Form.Item name="data" rules={[{ validator: validateTable }]}>
            <EditableTable append={false} />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default Custom;
