import { Layout, LayoutProps } from "antd";
import React from "react";
import useTitle from "../hooks/useTitle";

const { Content } = Layout;

interface BlankLayoutProps extends LayoutProps {
  title?: string;
  children: React.ReactNode;
}

const BlankLayout = ({ title, children, ...rest }: BlankLayoutProps) => {
  useTitle(title);

  return (
    <Layout {...rest}>
      <Content className="fill-screen">{children}</Content>
    </Layout>
  );
};

export default BlankLayout;
