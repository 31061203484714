

import { 
  Card, 
  PageHeader, 
  message, 
  Tree, 
  Row, 
  Col,
  Affix,
  Button,
  Modal
} from 'antd'
import "./index.css";
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next";
import BaseLayout from "../../components/BaseLayout";
import spuServices from '../../services/spu';
import React, { useEffect, useRef, useState } from 'react';

import ClassifyInfo from './components/ClassifyInfo';
import classifyServices from '../../services/classify'

const ClassifyList = () => {

  const { t } = useTranslation();
  const [treeData, setTreeData] = useState([]);
  const { confirm } = Modal;
  const infoRef = useRef<any>(null)

  useEffect(() => {
    getList();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTreeSelect = (item: any) => {
    // console.log("选中节点", item);

    if(!item.length) {
      return;
    }
    
    getCatInfo(item[0], 1);
  }

  // type: 0=>初始化  1=>选择  2=>无操作
  const getCatInfo = async (id: string, type: number = 0) => {
    try {
      const res = await classifyServices.catInfo({catID: id})
      const { response } = res.data;
      if(response) {
        infoRef.current && infoRef.current.info(response.info)
      }
    } catch (error) {
      message.error("Error")
    }
  }

  const onDelete = async (e: any, item: any) => {
    confirm({
      title: t("classify.delete.warning"),
      icon: <ExclamationCircleOutlined />,
      content: t("classify.delete.content"),
      centered: true,
      okText: t("classify.delete.confirm"),
      cancelText: t("classify.delete.cancel"),
      onOk: async () => {
        try {
          const res = await classifyServices.catInfoDel({catID: item.key});
          const { response } = res.data;
          if(response) {
            getList()
            message.success("Success")
          }
        } catch (error) {
          message.success("Error")
        }
      },
      onCancel() {
        
      }
    });
    e.stopPropagation();
  }

  const onSubmit = () => {
    infoRef.current && infoRef.current.submit()
  }

  const onCreate = () => {
    infoRef.current && infoRef.current.create()
  }

  const submitStatus = () => {
    getList(2)
  }

  const transTreeData = (data: any) => {
    if(!data) return []
    for(let i=0; i<data.length; i++) {
      let item = data[i];
      item["title"] = (
        <>
          {item.name} <DeleteOutlined style={{fontSize: "14px"}} onClick={(e) => onDelete(e, item)} />
        </>
      );
      item["key"] = item.id;
      if(item.children && item.children.length) {
        transTreeData(item.children)
      }
    }
    return data;
  }

  const getList = async (type?: any) => {
    try {
      const res = await spuServices.getCategories();
      const { response } = res.data;
      if(response) {
        setTreeData(transTreeData(response))
        let id = ''
        for (let i=0; i<response.length; i++) {
          let item = response[i];
          if(i === 0) {
            id = (item.children && item.children.length?item.children[0].id:item.id)
          }
        }
        if(id && type !== 2) getCatInfo(id, type)
      }
    } catch (error) {
      message.error("Error")
    }
  }

  return (
    <BaseLayout title={t("route.classifyList")}>
      <PageHeader
        title={t("route.classifyList")}
        style={{ padding: "16px 0" }}
        extra={[ 
          <Affix key={"classifyAffix"} offsetTop={14}>
            <div>
              <Button style={{ marginRight: "8px" }} type="primary" onClick={onSubmit}>{t("classify.submit")}</Button>
              <Button type="primary" onClick={onCreate}>{t("classify.create")}</Button>
            </div>
          </Affix>
        ]}
      />
      <Card>
      <Row wrap={false}>
          <Col flex="300px">
            <div className="classify-left-title">{t("classify.classifyName")}</div>
            {
              treeData.length?(
              <Tree
                key={"classifyKey"}
                defaultExpandAll  
                onSelect={onTreeSelect}
                treeData={treeData}
              />
              ):null
            }
          </Col>
          <Col flex="auto">
            <ClassifyInfo ref={infoRef} submitStatus={submitStatus} optionList={treeData} />  
          </Col>
        </Row>
      </Card>
    </BaseLayout>
  )
}

export default ClassifyList;