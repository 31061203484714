import {
  Form,
  FormProps,
  InputNumber,
  Radio,
  Input,
  Select,
  Row,
  Col,
} from "antd";

import EditableTable, { RowDataType } from "../../../components/EditableTable";

import { useTranslation } from "react-i18next";

import { getSizeUnit } from "../../../utils";

const { Option } = Select;

// spu 表单

export const checkedRowLength = (row: RowDataType[]): boolean => {
  const rowLength = row.length;
  return rowLength > 3;
};

export const checkedCellLength = (row: RowDataType[]): boolean => {
  const rowLength = row.length;
  const cellsLength = row.reduce(
    (pre: any, cur: any): any => ({
      q: pre.q + (cur.q === null || cur.q === undefined ? 0 : 1),
      h: pre.h + (cur.h === null || cur.h === undefined ? 0 : 1),
      eff: pre.eff + (cur.eff === null || cur.eff === undefined ? 0 : 1),
      npsh: pre.npsh + (cur.npsh === null || cur.npsh === undefined ? 0 : 1),
    }),
    {
      q: 0,
      h: 0,
      eff: 0,
      npsh: 0,
    }
  );
  const { q, h, eff, npsh } = cellsLength;
  const cellsInTableLength = [q, h, eff];

  if (cellsLength.npsh !== 0) cellsInTableLength.push(npsh);
  if (cellsInTableLength.every((val) => val === rowLength)) return true;
  return false;
};

const SpuForm = (props: FormProps) => {
  const { t } = useTranslation();

  const validateTable = (_: any, row: RowDataType[]) => {
    const isRowLengthValid = checkedRowLength(row);
    if (!isRowLengthValid)
      return Promise.reject(new Error(t("spuForm.validate.error1")));

    const isCellLengthValid = checkedCellLength(row);
    if (isCellLengthValid) return Promise.resolve();
    return Promise.reject(new Error(t("spuForm.validate.error2")));
  };

  return (
    <Form
      {...props}
      onValuesChange={({ size }) => {
        if (size !== undefined) {
          const sizeUnit = getSizeUnit(size);
          props.form?.setFieldsValue({ sizeUnit });
        }
      }}
      initialValues={{
        ...props.initialValues,
        sizeUnit: getSizeUnit(props.initialValues?.size),
      }}
    >
      <Form.Item
        label={t("spuForm.frequency.label")}
        name="freq"
        required
        rules={[{ required: true, message: t("spuForm.frequency.error") }]}
      >
        <Radio.Group>
          <Radio.Button value={50}>50 Hz</Radio.Button>
          <Radio.Button value={60}>60 Hz</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={t("spuForm.range.label")} required>
        <Form.Item
          name="start"
          noStyle
          rules={[{ required: true, message: t("spuForm.range.error") }]}
          style={{ display: "inline-block" }}
        >
          <InputNumber placeholder={t("spuForm.range.start")} />
        </Form.Item>
        <span
          style={{
            display: "inline-block",
            width: "24px",
            lineHeight: "32px",
            textAlign: "center",
          }}
        >
          -
        </span>
        <Form.Item
          name="end"
          noStyle
          rules={[{ required: true, message: t("spuForm.range.error1") }]}
          style={{ display: "inline-block" }}
        >
          <InputNumber placeholder={t("spuForm.range.end")} />
        </Form.Item>
      </Form.Item>
      <Row gutter={8}>
        <Col>
          <Form.Item label={t("spuForm.size.label")} required>
            <Input.Group compact>
              <Form.Item
                noStyle
                name="size"
                rules={[{ required: true, message: t("spuForm.size.error") }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item name="sizeUnit" noStyle>
                <Select disabled>
                  <Option key="mm" value="mm">
                    mm
                  </Option>
                  <Option key="%" value="%">
                    %
                  </Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={t("spuForm.rpm.label")} required>
            <Input.Group compact>
              <Form.Item
                noStyle
                name="speed"
                rules={[{ required: true, message: t("spuForm.rpm.error") }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item noStyle>
                <Input value="r/min" disabled style={{ width: "30%" }} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={t("spuForm.formula.label")}
        name="formula"
        required
        rules={[{ required: true, message: t("spuForm.formula.error") }]}
      >
        <Radio.Group>
          <Radio.Button value={true}>
            {t("spuForm.formula.select.two")}
          </Radio.Button>
          <Radio.Button value={false}>
            {t("spuForm.formula.select.one")}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={t("spuForm.dataset.label")}
        name="dataset"
        required
        rules={[
          { required: true, message: t("spuForm.dataset.error") },
          { validator: validateTable },
        ]}
      >
        <EditableTable />
      </Form.Item>
    </Form>
  );
};
export default SpuForm;
