import React from "react";
import logo from "../logo.svg";
import "./Brand.css";

interface BrandProps {
  fontSize?: string;
  lineHeight?: string;
  letterSpacing?: string;
  logoSize?: string;
  align?: "left" | "right" | "center";
}

const Brand = ({
  fontSize = "24px",
  lineHeight = "24px",
  letterSpacing = "-0.18px",
  logoSize = "32px",
  align = "center",
}: BrandProps) => {
  return (
    <h1 style={{ textAlign: align, height: logoSize }}>
      <a
        id="logo"
        href="/"
        style={{
          fontSize,
          lineHeight,
          letterSpacing,
        }}
      >
        <img
          alt="logo"
          src={logo}
          style={{
            width: logoSize,
            height: logoSize,
          }}
        />
        aikon
      </a>
    </h1>
  );
};

export default Brand;
