import { useEffect, useState } from "react";

interface UserTokenData {
  username: string;
  token: string;
  role: string;
}

const useAuth = () => {
  const ITEM = "aikonLoggedUser";
  const [auth, setAuth] = useState<UserTokenData | undefined>(() => {
    const item = window.localStorage.getItem(ITEM);
    if (!item) return;
    return JSON.parse(item);
  });
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    setIsAdmin(auth !== undefined && auth.role === "ROLE_ADMIN");
  }, [auth]);

  const updateAuth = (user: UserTokenData) => {
    window.localStorage.setItem(ITEM, JSON.stringify(user));
    setAuth(user);
  };

  const delAuth = () => {
    window.localStorage.removeItem(ITEM);
    setAuth(undefined);
  };

  return { auth, isAdmin, updateAuth, delAuth };
};

export default useAuth;
