import "./index.css";
import { useEffect, useState } from "react";
import { 
  FormOutlined,
  RedoOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { 
  PageHeader,
  Card,
  Select,
  Button,
  Tabs,
  Checkbox,
  Tag,
  Tooltip,
  Radio,
  Table,
  message
} from 'antd'
import { useTranslation } from "react-i18next";
import BaseLayout from "../../components/BaseLayout";

import spuServices from "../../services/spu";
import { useLocation } from 'react-router-dom';

const { Option } = Select

// 和skuAttr 一样，这份暂时没用，先留着

export default function SkuProperties() {
  const { t } = useTranslation()
  const location = useLocation()

  // 顶部
  const [spuName, setSpuName] = useState('')
  const [spuModel, setSpuModel] = useState([])

  // 规格
  const [btnList, setBtnList] = useState<Array<any>>([])
  const [tabList, setTabList] = useState<Array<any>>([])
  const [changeBtn, setChangeBtn] = useState('a')
  const [data, setData] = useState<Array<any>>([])
  const [datas] = useState<Array<any>>([])
  const [columns, setColumns] = useState<Array<any>>([
    {
      key: "skuKey",
      title: "sku 键名",
      dataIndex: "skuKey",
      with: 180,
    },
    {
      key: "skuNode",
      title: "sku 编码",
      dataIndex: "node",
      with: 280,
    },
    {
      key: "action",
      title: () => {
        return (
          <div>
            <span>操作</span>
            <div className="table-action-btn">
              <Button 
                type="primary"
              >
                重置
              </Button>
              <Button
                style={{
                  "background": changeBtn === 'a' ? "#67C23A" : "#F56C6C",
                  "borderColor": changeBtn === 'a' ? "#67C23A" : "#F56C6C",
                  "color": "#fff",
                }}
              >
                {changeBtn === 'a' ? '全部有效' : '全部无效'}
              </Button>
            </div>
          </div>
        )
      },
      dataIndex: "action",
      with: 280,
      align: "center",
      render: (_: any, record: any) => {
        return (
          <Button
            style={{
              "background": changeBtn === 'a' ? "#67C23A" : "#F56C6C",
              "borderColor": changeBtn === 'a' ? "#67C23A" : "#F56C6C",
              "color": "#fff",
            }}
          >
            {changeBtn === 'a' ? '有效' : '无效'}
          </Button>
        )
      }
    },
  ])

  // 属性
  const [btnAttribute, setBtnAttribute] = useState<Array<any>>([])
  const [tabAttribute, setTabAttribute] = useState<Array<any>>([])

  // 非标规格
  const [btnNoStandard, setBtnNoStandard] = useState<Array<any>>([])
  const [tabNoStandard, setTabNoStandard] = useState<Array<any>>([])

  useEffect(() => {
    getData(-1)
  })
  
  const getData = async (modelID: number) => {
    try {
      const res = await spuServices.getSkuListBySpu(location.state.id, modelID)

      const {response} = res.data

      if (response) {
        const {
          sameSpu,
          spuModel,
          spec,
          attr,
          cusSpec
        } = response

        if (sameSpu) {
          setSpuName(sameSpu.nameLang)
        }

        if (spuModel) {
          setSpuModel(spuModel)
        }

        if (spec) {
          spec && spec.forEach((item: any, index: number) => {
            item['checkList'] = []
          })
          
          let newSpec = spec.filter((val: any) => val.select.length === 0) 
          let newSpecs = spec.filter((val: any) => val.select.length !== 0)

          setBtnList(newSpec)
          
          // setBtnList((prevArray: any) => {
          //   prevArray.forEach((item: any)=>{
          //     Object.assign(item, {checkList: []})
          //   })

          //   return [...prevArray]
          // })

          setTabList(newSpecs)
        }

        if (attr) {
          let newAttr = attr.filter((val: any) => val.select === 0)
          let newAttrs = attr.filter((val: any) => val.select !== 0)

          setBtnAttribute(newAttr)
          setTabAttribute(newAttrs)
        }

        if (cusSpec) {
          setBtnNoStandard(cusSpec)
        }
      }
    } catch (error) {
      message.error("error")
    }
  }

  // 列表数据变化
  const dataChange = (vItem: any, checkList: any, v: any) => {
    // checkList.indexOf(vItem.id) !== -1 ? setData(data.filter(val => val.id !== vItem.id)) : setData([...data, vItem]) 
    
    if (checkList.indexOf(vItem.id) !== -1) {
      setData(data.filter(val => val.id !== vItem.id))

      setColumns((parent: any) => {
        for (let index = 0; index < parent.length; index++) {
          if (parent[index].id === v.id) {
            parent.splice(index, 1)
          }
        }

        return [...parent]
      })
    } else {
      setData([...data, vItem])
      
      setColumns((pre: any) => {
        if (pre.find((val: any) => val.id === v.id)) {
          return [...pre]
        }

        let obj = {
          id: v.id,
          key: "short_name",
          dataIndex: "short_name",
          with: 180,
          title: () => {
            return (
              <div>
                <div>
                  {v.short_name}
                </div>
                <Select
                  style={{
                    width: 160
                  }}
                  size="small"
                  options={v.item}
                  fieldNames={{ 
                    label: 'short_name', 
                    value: 'id', 
                  }} 
                />
              </div>
            )
          }
        }
        
        return [obj, ...pre]
      })
    }
  }

  // top select
  const handleSelect = (id: number) => {
    getData(id)

    setTabList([])
    setData([])
    setColumns((pre: any) => {
      pre.splice(0, pre.length - 3)

      return [...pre]
    })
    setTabAttribute([])
    setTabNoStandard([])
  }

  // 多选带边框按钮变色
  const handleCheckList = (val: any, key: number) => {
    setTabList((preList: any) => {
      let item = preList[key]

      if (item) {
        item.checkList = val
      }

      return [...preList]
    })
  }

  // 添加已选
  const handleDelBtn = (id: number, index: number, type: string) => {
    if (type === 'specifications') {
      let arr = btnList.filter(val => val.id === id)
    
      // sort排序
      setTabList([...tabList, ...arr].sort((a, b) => a.id - b.id))

      btnList.splice(index, 1)
    }
    
    if (type === 'attribute') {
      let arrAttr = btnAttribute.filter(val => val.id === id)

      setTabAttribute([...tabAttribute, ...arrAttr].sort((a, b) => a.id - b.id))

      btnAttribute.splice(index, 1)
    }

    if (type === 'noStandard') {
      let arrStand = btnNoStandard.filter(val => val.id === id)

      setTabNoStandard([...tabNoStandard, ...arrStand].sort((a, b) => a.id - b.id))

      btnNoStandard.splice(index, 1)
    }
  }

  // 删除已选
  const handleOnClose = (id: number, index: number, e: any, type: string) => {
    if (type === 'specifications') {

      let arr = tabList.filter(val => val.id === id)

      setBtnList([...btnList, ...arr].sort((a, b) => a.id - b.id))

      setTabList((list: any) => {
        let item = list[index]
        
        // 两个数组通过data的id和item.item的id比较，返回去重后的data
        let newArr = data.filter((v) =>
          item.item.every((val: any) => val.id !== v.id)
        )

        if (item) {
          item.checkList = []
          tabList.splice(index, 1)
          setData(newArr)

          setColumns((parent: any) => {
            for (let index = 0; index < parent.length; index++) {
              if (parent[index].id === item.id) {
                parent.splice(index, 1)
              }
            }
    
            return [...parent]
          })
        }

        return [...list]
      })
    }
    
    if (type === 'attribute') {
      let arrAttr = tabAttribute.filter(val => val.id === id)
      
      setBtnAttribute([...btnAttribute, ...arrAttr])

      tabAttribute.splice(index, 1)
    }

    if (type === 'noStandard') {
      let arrStand = tabNoStandard.filter(val => val.id === id)
      
      setBtnNoStandard([...btnNoStandard, ...arrStand])

      tabNoStandard.splice(index, 1)
    }

    e.preventDefault()
  }

  // 切换按钮
  const handleChangeBtn = (e: any) => {
    setChangeBtn(e.target.value)
  }

  return (
    <BaseLayout title={t("spuList.action.properties")}>
      <PageHeader
        title={t("skuProperties.title")}
        style={{ padding: "16px 0" }}
      />

      <Card style={{ marginTop: 24 }}>
        <div className="top">
          <div className="t-item">
            <span>
              spu：
            </span>
            <span>
              {spuName}
            </span>
          </div>
          <div className="t-item">
            <span>
              模型：
            </span>
            <Select
              defaultValue={1}
              style={{
                width: 215,
              }}
              options={spuModel}
              fieldNames={{ 
                label: 'name', 
                value: 'id',
              }}
              onSelect={(key:number) => handleSelect(key)}
            />
          </div>
          <div className="t-item">
            <span>
              调用类似SPU模型：
            </span>
            <span>
              sadsk
            </span>
            <FormOutlined className="ti-icon"/>
            <Button
              className="btn"
              icon={
                <RedoOutlined rotate={180}/>
              }
            />
          </div>
        </div>
      </Card>

      <Card style={{ marginTop: 24 }}>
        <Tabs 
          className="tabs" 
          defaultActiveKey="1" 
          type="card"
        >
          <Tabs.TabPane tab="规格" key="1">
            <div style={{'padding': '15px'}}>
              <h3 style={{'margin': '0 0 20px 5px'}}>
                可选规格:
              </h3>
              <div>
                {
                  btnList.map((v: any, i: number) => {
                    const {
                      id,
                      short_name
                    } = v

                    return (
                      <Button 
                        className="btn btn-list"
                        key={i}
                        onClick={() => handleDelBtn(id, i, 'specifications')}
                      >
                        {id + '：' + short_name}
                        <PlusOutlined />
                      </Button>
                    )
                  })
                }
              </div>
            </div>
          
            <div style={{'padding': '15px'}}>
              <div className="submit-item">
                <h3 className="si-h3">
                  已选规格：
                  <span className="plus-icon">
                    <PlusOutlined />
                  </span>
                </h3>
                <Button className="btn">
                  提交
                </Button>
              </div>
              {
                tabList.map((v: any, i: number) => {
                  const {
                    id,
                    short_name,
                    checkList,
                    item
                  } = v

                  return (
                    <Checkbox.Group 
                      className="tab-list"
                      key={i}
                      value={checkList}
                      onChange={($event) => handleCheckList($event, i)}
                    >
                      <div className="checkbox-div">
                        <Tooltip title={id + '：' + short_name}>
                          <Tag 
                            className="tl-header"
                            closable
                            onClose={(e) => handleOnClose(id, i, e, 'specifications')}
                          >
                            {id + '：' + short_name}
                          </Tag>
                        </Tooltip>
                        {
                          item.map((vItem: any, iItem: number) => {
                            const {
                              id,
                              short_name
                            } = vItem
                            
                            return (
                              <div 
                                className={`checkbox-list ${checkList.indexOf(id) !== -1 ? 'checkbox-list-checked': ''}`}
                                key={iItem}
                              >
                                <Checkbox 
                                  className="checkbox"
                                  value={id}
                                  onChange={() => dataChange(vItem, checkList, v)}
                                >
                                  {id + '：' + short_name}
                                </Checkbox>
                              </div>
                            )
                          })
                        }
                        <span className="plus-icon">
                          <PlusOutlined />
                        </span>
                      </div>
                    </Checkbox.Group>
                  )
                })
              }
            </div>

            <div style={{'padding': '15px'}}>
              <Radio.Group
                defaultValue="a"
                buttonStyle="solid"
              >
                <Radio.Button 
                  value="a"
                  onChange={(e) => handleChangeBtn(e)}
                >
                  当前无效
                </Radio.Button>
                <Radio.Button 
                  value="b"
                  onChange={(e) => handleChangeBtn(e)}
                >
                  已选有效
                </Radio.Button>
                <Button 
                  style={{
                    "background": "#67C23A",
                    "borderColor": "#67C23A",
                    "color": "#fff",
                    "margin": "0 20px"
                  }}    
                >
                  导出
                </Button>
                <Button type="primary">
                  导入
                </Button>
              </Radio.Group>
              <Table
                style={{"margin": "30px 0 50px"}}
                columns={columns}
                scroll={{ x: 325 }}
                dataSource={changeBtn === 'a' ? data : datas}
                rowKey={record => record.key}
              >
              </Table>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="属性" key="2">
            <div style={{'padding': '15px'}}>
              <h3 style={{'margin': '0 0 20px 5px'}}>
                可选属性:
              </h3>
              <div>
                {
                  btnAttribute.map((v: any, i: number) => {
                    const {
                      id, 
                      get_pdc_lang_info
                    } = v

                    return (
                      <Button
                        className="btn btn-list"
                        key={i}
                        onClick={() => handleDelBtn(id, i, 'attribute')}
                      >
                        {id + '：' + get_pdc_lang_info.value}
                        <PlusOutlined />
                      </Button>
                    )
                  })
                }
              </div>
            </div>

            <div style={{'padding': '15px'}}>
              <div className="submit-item">
                <h3 className="si-h3">
                  已选属性：
                  <span className="plus-icon">
                    <PlusOutlined />
                  </span>
                </h3>
                <Button className="btn">
                  提交
                </Button>
              </div>
              <div className="tab-list">
                {
                  tabAttribute.map((v: any, i: number) => {
                    const {
                      id,
                      get_pdc_lang_info,
                      item,
                      select
                    } = v

                    const  defaultAttrSelect = item.filter((val: any) => val.id === select)

                    return (
                      <div 
                        key={i}
                        style={{"margin": "5px 5px 5px 10px"}}
                      >
                        <Tooltip title={id + '：' + get_pdc_lang_info.value}>
                          <Tag 
                            className="tl-header"
                            closable
                            onClose={(e) => handleOnClose(id, i, e, 'attribute')}
                          >
                            {id + '：' + get_pdc_lang_info.value}
                          </Tag>
                        </Tooltip>
                        <Select
                          style={{
                            width: 193,
                          }}
                          value={defaultAttrSelect[0] ? defaultAttrSelect[0].get_pdc_lang_info.value : '无'}
                        >
                          {
                            item.map((v:any, i:number) => {
                              const {
                                id,
                                get_pdc_lang_info
                              } = v

                              return (
                                <Option
                                  key={i}
                                  value={id}
                                >
                                  {get_pdc_lang_info.value}
                                </Option>
                              )
                            })
                          }
                        </Select>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="非标规格" key="3">
            <div style={{'padding': '15px'}}>
              <div className="submit-item">
                <h3 className="si-h3">
                  可选规格：
                </h3>
                <Button className="btn">
                  提交
                </Button>
              </div>
              <div>
                {
                  btnNoStandard.map((v: any, i: number) => {
                    const {
                      id, 
                      get_pdc_lang_info
                    } = v

                    return (
                      <Button
                        className="btn btn-list"
                        key={i}
                        onClick={() => handleDelBtn(id, i, 'noStandard')}
                      >
                        {id + '：' + get_pdc_lang_info.value}
                        <PlusOutlined />
                      </Button>
                    )
                  })
                }
              </div>
            </div>

            <div style={{'padding': '15px'}}>
              <h3 style={{'margin': '0 0 20px 5px'}}>
                已选规格:
              </h3>
              <div className="tab-list">
                {
                  tabNoStandard.map((v, i) => {
                    const {
                      id,
                      get_pdc_lang_info,
                    } = v

                    return (
                      <div 
                        key={i}
                        style={{"margin": "5px 5px 5px 10px"}}
                      >
                        <Tag 
                          className="tl-tag-list"
                          closable
                          onClose={(e) => handleOnClose(id, i, e, 'noStandard')}
                          color={'#909399'}
                        >
                          {id + '：' + get_pdc_lang_info.value}
                        </Tag>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </BaseLayout>
  )
}