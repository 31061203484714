import { ConfigProvider, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLoading from "../../../hooks/useLoading";
import usePagination from "../../../hooks/usePagination";
import { langMap } from "../../../i18n";
import spuServices from "../../../services/spu";
import { SpuFullData, Stock } from "../../../types";
import { stockMap } from "../../../utils";

// 选型表格

interface SelectionTableProps {
  stock: Stock;
  selected: number[];
}

const SelectionTable = ({ stock, selected }: SelectionTableProps) => {
  const { t, i18n } = useTranslation();
  
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      width: 100,
    },
    {
      title: t("spuList.columns.name"),
      dataIndex: "spu",
      key: "spu",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Q",
      dataIndex: "q",
      key: "q",
      width: 100,
      ellipsis: true,
    },
    {
      title: "H",
      dataIndex: "h",
      key: "h",
      width: 100,
      ellipsis: true,
    },
    {
      title: "EFF",
      dataIndex: "eff",
      key: "eff",
      width: 100,
      ellipsis: true,
    },
    {
      title: "P",
      dataIndex: "p",
      key: "p",
      width: 100,
      ellipsis: true,
    },
    {
      title: "NPSH",
      dataIndex: "npsh",
      key: "npsh",
      width: 100,
      ellipsis: true,
    },
    {
      key: "action",
      fixed: "right" as const,
      width: 80,
      dataIndex: "action",
      title: t("spuList.columns.action"),
      render: (text: string, record: any, index: number) => {
        const { id, q, h } = record;

        if (q && h) {
          return (
            <Link to={`/spus/${id}?q=${q}&h=${h}`}>
              {t("spuSelection.details")}
            </Link>
          );
        }

        return <Link to={`/spus/${id}`}>{t("spuSelection.details")}</Link>;
      },
    },
  ];

  const [dataSource, setDataSource] = useState<SpuFullData[]>([]);
  const { current, pageSize, total, setPagination } = usePagination(1, 10);

  const getSpuList = async (page: number = 1, pageSize: number = 10) => {
    const res = await spuServices.getSelection({
      page,
      limit: pageSize,
      full: 1,
      stock: stockMap[stock],
      cat: selected.join(",") || undefined,
    });

    const { totalCount, spus } = res.data.response;
    
    setPagination(page, pageSize, totalCount);
    setDataSource(
      spus.map((item: any) => ({
        id: item.id,
        spu: item.spu,
        start: item.valid_start,
        end: item.valid_end,
      }))
    );
  };

  const [onPageChange, loading] = useLoading(getSpuList);
  
  useEffect(() => {
    onPageChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stock, JSON.stringify(selected)]);

  return (
    <ConfigProvider locale={langMap(i18n.language)}>
      <Table
        columns={columns}
        bordered={false}
        scroll={{ x: 325 }}
        dataSource={dataSource}
        pagination={{
          current: current,
          pageSize: pageSize,
          total: total,
          onChange: onPageChange,
          showSizeChanger: false,
          hideOnSinglePage: true,
          style: { margin: "24px 24px" },
        }}
        rowKey="id"
        loading={loading}
      />
    </ConfigProvider>
  );
};

export default SelectionTable;
