import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Radio, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import useSpuPoints from "../../../stores/spuPoints";

const { useForm } = Form;

const Unit = ({ name, style }: { name: string; style?: any }) => {
  return <span style={{ display: "block", width: 30, ...style }}>{name}</span>;
};

let oldQuantity: any = '';
let oldHead: any = '';
let oldSg: any = '';

const PerformanceSpeedForm = () => {
  const [form] = useForm();
  const { t } = useTranslation();

  const {
    info,
    setPerformanceSpeedValue,
    setPerformanceSpeedItems,
    resetPerformanceSpeed,
  } = useSpuPoints();

  const { type } = info;

  const handleSubmit = (values: any) => {
    const { type, quantity, head, sg, speedItems } = values;

    if (quantity !== undefined && head !== undefined) {
      const finalQ: any = Number(quantity);
      const finalH: any = Number(head);

      setPerformanceSpeedValue({
        type,
        quantity: finalQ,
        head: finalH,
        sg: sg || 1,
      });
    }

    if (speedItems && speedItems.length > 0) {
      setPerformanceSpeedItems(speedItems);
    }
  };

  const handleReset = () => {
    form.resetFields();
    resetPerformanceSpeed();
  };

  const handleQuantityBlur = () => {
    let {
      quantity: finalValue
    } = form.getFieldsValue();

    if (finalValue === '') {
      form.setFieldsValue({
        quantity: finalValue
      });
  
      oldQuantity = finalValue;

      return;
    }

    const finalVs = `${finalValue}`.split('.');

    const first = finalVs[0] || '0';
    let two = finalVs[1] || '0';
    two = two.slice(0, 1);

    finalValue = `${first}${two ? '.' : ''}${two}`;

    form.setFieldsValue({
      quantity: finalValue
    });

    oldQuantity = finalValue;
  }

  const handleQuantityChange = (e: any) => {
    const length = oldQuantity.length;

    const value = e.target.value;
    const lengthTwo = value.length
    
    if (lengthTwo > length) {
      let newValue = value.slice(length);
  
      newValue = newValue.replace(/\D/g, '.');
  
      let finalValue = `${oldQuantity}${newValue}`;
  
      form.setFieldsValue({
        quantity: finalValue
      });
  
      oldQuantity = finalValue;
    } else {
      form.setFieldsValue({
        quantity: value
      });
  
      oldQuantity = value;
    }
  }

  const handleHeadBlur = () => {
    let {
      head: finalValue
    } = form.getFieldsValue();

    if (finalValue === '') {
      form.setFieldsValue({
        head: finalValue
      });
  
      oldHead = finalValue;

      return;
    }

    const finalVs = `${finalValue}`.split('.');

    const first = finalVs[0] || '0';
    let two = finalVs[1] || '0';
    two = two.slice(0, 1);

    finalValue = `${first}${two ? '.' : ''}${two}`;

    form.setFieldsValue({
      head: finalValue
    });

    oldHead = finalValue;
  }

  const handleHeadChange = (e: any) => {
    const length = oldHead.length;

    const value = e.target.value;
    const lengthTwo = value.length
    
    if (lengthTwo > length) {
      let newValue = value.slice(length);
  
      newValue = newValue.replace(/\D/g, '.');
  
      let finalValue = `${oldHead}${newValue}`;
  
      form.setFieldsValue({
        head: finalValue
      });
  
      oldHead = finalValue;
    } else {
      form.setFieldsValue({
        head: value
      });
  
      oldHead = value;
    }
  }

  const handleSgBlur = () => {
    let {
      sg: finalValue
    } = form.getFieldsValue();

    if (finalValue === '') {
      form.setFieldsValue({
        sg: finalValue
      });
  
      oldSg = finalValue;

      return;
    }

    const finalVs = `${finalValue}`.split('.');

    const first = finalVs[0] || '0';
    let two = finalVs[1] || '00';
    two = two.slice(0, 2);

    finalValue = `${first}${two ? '.' : ''}${two}`;

    const number = Number(finalValue);

    if (number < 0.2) {
      finalValue = 0.2;
    }

    if (number > 5.0) {
      finalValue = 5.0;
    }

    form.setFieldsValue({
      sg: finalValue
    });

    oldSg = finalValue;
  }

  const handleSgChange = (e: any) => {
    const length = oldSg.length;

    const value = e.target.value;
    const lengthTwo = value.length
    
    if (lengthTwo > length) {
      let newValue = value.slice(length);
  
      newValue = newValue.replace(/\D/g, '.');
  
      let finalValue = `${oldSg}${newValue}`;
  
      form.setFieldsValue({
        sg: finalValue
      });
  
      oldSg = finalValue;
    } else {
      form.setFieldsValue({
        sg: value
      });
  
      oldSg = value;
    }
  }

  return (
    <Form
      form={form}
      name="settings"
      layout="vertical"
      onReset={handleReset}
      onFinish={handleSubmit}
      initialValues={{ type: 0, sg: 1 }}
    >
      <Form.Item label={t("chart.formula")} name="type">
        <Radio.Group>
          <Radio.Button value={0}>{t("spuForm.formula.select.one")}</Radio.Button>
          <Radio.Button value={1} disabled={!type}>
            {t("spuForm.formula.select.two")}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Q" name="quantity" required>
        <Input
          style={{ width: "100%" }}
          addonAfter={<Unit name="m³/h" />}
          onChange={handleQuantityChange}
          onBlur={handleQuantityBlur}
        />
      </Form.Item>
      <Form.Item label="H" name="head" required>
        <Input
          style={{ width: "100%" }}
          addonAfter={<Unit name="m" />}
          onChange={handleHeadChange}
          onBlur={handleHeadBlur}
        />
      </Form.Item>
      <Form.Item label={t("chart.sg")} name="sg">
        <Input
          style={{ width: "100%" }}
          onChange={handleSgChange}
          onBlur={handleSgBlur}
        />
      </Form.Item>
      <Form.Item
        label={t("spuForm.rpm.label")}
        rules={[
          {
            validator: async (_: any, names: any) => {
              if (!names || names.length < 2) {
                return Promise.reject(new Error(t("spuForm.rpm.error")));
              }
            },
          },
        ]}
      >
        <Form.List name="speedItems">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key}>
                  <Form.Item
                    {...field}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: t("speedChart.speed.error"),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("speedChart.speed.placeholder")}
                      style={{ width: "90%" }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    style={{ width: "10%" }}
                    onClick={() => remove(field.name)}
                  />
                </Form.Item>
              ))}
              <Form.Item noStyle>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{ width: "100%" }}
                >
                  {t("speedChart.speed.add")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item noStyle>
        <Button type="primary" htmlType="submit">
          {t("chart.submit")}
        </Button>
        <Button type="ghost" htmlType="reset" style={{ marginLeft: 10 }}>
          {t("chart.clear")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PerformanceSpeedForm;
