import { useEffect, useState } from "react";

import { ConfigProvider, Table, Popover } from "antd";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { langMap } from "../../../i18n";

import {
  SpuFullData
} from "../../../types";

import usePagination from "../../../hooks/usePagination";
import PerformanceChart from "../../Spu/Performance/PerformanceChart";

// import Rectangle from "../../../libs/rectangle";

// 选型筛选表格

// const findStartPoint = (loq: number[], loh: number[], start: number) => {
//   const i = loq.findIndex((x) => x >= start);
//   return {
//     x: loq[i],
//     y: loh[i],
//   };
// };

// const findEndPoint = (loq: number[], loh: number[], end: number) => {
//   let i = loq.findIndex((x) => x > end);
//   if (i - 1 < 0) i = loq.length;

//   return {
//     x: loq[i - 1],
//     y: loh[i - 1],
//   };
// };

// const isRectangleOverlap = (q: number, h: number, curveInterval: Interval) => {
//   const auxInterval = {
//     p: { x: q, y: h },
//     q: { x: q * 1.2, y: (h / q ** 2) * (q * 1.2) ** 2 },
//   };
//   const rect1 = new Rectangle(auxInterval.p, auxInterval.q);
//   const rect2 = new Rectangle(curveInterval.p, curveInterval.q);

//   return rect1.overlapWith(rect2);
// };

const SelectionResult = ({
  q,
  h,
  filteredSpus
}: any) => {
  const { t, i18n } = useTranslation();
  
  const columns = [
    {
      title: "曲线",
      dataIndex: "canvas",
      width: 112,
      render: (text: string, record: any, index: number) => {
        const {
          Q,
          H,
          EFF,
          NPSH,
          name,
          start,
          end,
          speed,
          freq
        } = record;

        const info: any = {
          diameter: 0,
          frequency: freq,
          range: {
            start,
            end
          },
          speed
        };

        const dataset: any = {
          Q,
          H,
          EFF,
          NPSH,
        };

        const fieldsValue: any = {
          type: 0,
          quantity: q,
          head: h,
          sg: 1
        };

        return (
          <Popover
            placement='right'
            content={
              <PerformanceChart
                renderType='popover'
                key={name}
                name={name}
                info={info}
                dataset={dataset}
                performance={fieldsValue}
              />
            }
          >
            <div style={{cursor: "pointer"}}>
              <PerformanceChart
                renderType='table'
                key={name}
                name={name}
                info={info}
                dataset={dataset}
                performance={fieldsValue}
              />
            </div>
          </Popover>
        );
      }
    },
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      width: 80,
    },
    {
      title: t("spuList.columns.name"),
      dataIndex: "spu",
      key: "spu",
      width: 220,
      ellipsis: true,
    },
    {
      title: "Q",
      dataIndex: "q",
      key: "q",
      width: 70,
      ellipsis: true,
    },
    {
      title: "H",
      dataIndex: "h",
      key: "h",
      width: 70,
      ellipsis: true,
    },
    {
      title: "EFF",
      dataIndex: "eff",
      key: "eff",
      width: 70,
      ellipsis: true,
      sorter: (a: any, b: any) => b.p - a.p,
    },
    {
      title: "P",
      dataIndex: "p",
      key: "p",
      width: 80,
      ellipsis: true,
      // sorter: (a: any, b: any) => a.p - b.p,
    },
    {
      title: "NPSH",
      dataIndex: "npsh",
      key: "npsh",
      width: 70,
      ellipsis: true,
    },
    {
      key: "action",
      fixed: "right" as const,
      width: 80,
      dataIndex: "action",
      title: t("spuList.columns.action"),
      render: (text: string, record: any, index: number) => {
        const { id, q, h } = record;

        if (q && h) {
          return (
            <Link to={`/spus/${id}?q=${q}&h=${h}`}>
              {t("spuSelection.details")}
            </Link>
          );
        }

        return <Link to={`/spus/${id}`}>{t("spuSelection.details")}</Link>;
      },
    },
  ];

  let count: any = filteredSpus.length;

  const [dataSource, setDataSource] = useState<SpuFullData[]>([]);
  const { current, pageSize, total, setPagination } = usePagination(1, 10);

  const onPageChange = (page: any) => {
    setPagination(page, pageSize, count);
  }

  useEffect(() => {
    setDataSource(filteredSpus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, h, JSON.stringify(filteredSpus)]);

  return (
    <ConfigProvider locale={langMap(i18n.language)}>
      <Table
        columns={columns}
        bordered={false}
        scroll={{ x: 325 }}
        dataSource={dataSource}
        pagination={{
          current: current,
          pageSize: pageSize,
          total: total,
          onChange: onPageChange,
          showSizeChanger: false,
          hideOnSinglePage: true,
          style: { margin: "24px 24px" },
        }}
        rowKey="id"
      />
    </ConfigProvider>
  );
};

export default SelectionResult;
