import axios from "../libs/axios";

interface SupplierListParams {
  key: string;
  page?: number;
  pageSize?: number;
}

interface SupplierFormParmas {
  id?: string,
  name: string,
  code: string
}

// 返回供应商列表
const getList = async (params: SupplierListParams) => await axios.get("/supplier/supplierList", { params });

// 供应商删除
const supplierDel = async (params: {supplierID: number}) => await axios.post("/supplier/supplierDel", params);

// 供应商信息提交
const supplierInfoPut = async (params: SupplierFormParmas) => await axios.post("/supplier/supplierInfoPut", params);

const supplierServices = {
  getList,
  supplierDel,
  supplierInfoPut
};

export default supplierServices;