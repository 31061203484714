import { useState, useEffect } from "react";

import { EChartsType } from "echarts/core";

import Chart, { EChartsOption } from "../../../components/Chart";

import { exportTemplate, renderLabel } from "../../../libs/echar";

import useSpuPdfParam from "../../../stores/spuPdfParam";

import {
  SpuPointsInfo,
  MultiSpeedForm,
  SpuPointsDataset
} from "../../../stores/spuPoints";

import { zip, genRegressionModal } from "../../../utils";

// 多速度曲线图

interface MultiSpeedChartProps {
  name: string;
  info: SpuPointsInfo;
  dataset: SpuPointsDataset;
  multiSpeed: MultiSpeedForm;
}

const MultiSpeedChart = ({
  name,
  info,
  dataset,
  multiSpeed
}: MultiSpeedChartProps) => {
  const initialOption: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross"
      }
    },
    toolbox: {
      feature: {
        saveAsImage: {
          name: name + "_rpm"
        }
      }
    },
    xAxis: {
      name: "m³/h",
      type: "value",
      min: 0
    },
    yAxis: {
      name: "H(m)",
      type: "value",
      min: 0
    }
  };

  const [option, setOption] = useState(initialOption);

  useEffect(() => {
    const {
      speedItems
    } = multiSpeed;

    const {
      speed
    } = info;

    const QH = genRegressionModal(zip(dataset.Q, dataset.H));

    const speedSeries = speedItems.map((newSpeed, index): any => {
      const k = newSpeed / speed;
      const data = QH.dummyPoints.map((p) => [p[0] * k, p[1] * k ** 2]);

      return {
        id: index + 1,
        name: `Speed ${index + 1}`,
        type: "line",
        label: renderLabel(`${newSpeed} RPM`),
        symbolSize: 0,
        data,
        animation: false
      };
    });

    setOption({
      ...initialOption,
      series: [
        {
          id: 0,
          name: "original",
          type: "line",
          label: renderLabel(`${speed} RPM`),
          smooth: true,
          symbolSize: 0,
          data: QH.dummyPoints,
          animation: false
        },
        ...speedSeries
      ]
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(multiSpeed),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(dataset)
  ]);

  const { setMultiSpeedImage } = useSpuPdfParam();

  const handleChartChange = (instance: EChartsType) => {
    const result = instance.getDataURL(exportTemplate as any);

    const {
      speedItems
    } = multiSpeed;

    instance.getDataURL();

    if (speedItems.length > 0) {
      setMultiSpeedImage(result);
    }
  };

  return (
    <Chart
      option={option}
      notMerge
      style={{ width: 500, height: 600, margin: "auto" }}
      onChange={handleChartChange}
    />
  );
};

export default MultiSpeedChart;
