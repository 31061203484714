import { useTranslation } from "react-i18next";

import { Form, Button, Space, Drawer, message, Select } from "antd";

import { CloseOutlined } from "@ant-design/icons";

import AttributeTypeForm from "./AttributeTypeForm";

import { nameLangMap } from "../../i18n";

import useDrawer from "../../hooks/useDrawer";

import useModel, { AttributeState } from "../../stores/model";

import './EditAttribute.css';

const { useForm } = Form;

const childrenCount = (attributes: AttributeState[], index: number): number => {
  const currAttr = attributes.find((attribute, i) => i === index);

  return currAttr ? currAttr.list.length : 0;
};

const EditAttribute = ({
  rowIndex,
  data,
  classifyId
}: {
  rowIndex: number;
  data: any;
  classifyId: any;
}) => {
  const [form] = useForm();
  const { t, i18n } = useTranslation();

  const {
    id,
    name,
    type,
    order,
  } = data;
  
  const {
    classify,
    attributes,
    removeAttribute,
    updateAttribute,
    updateClassifyAttr
  } = useModel();

  const includeChildren = childrenCount(attributes, rowIndex) > 0;

  let selectId: any = classifyId;

  const submitHook = async () => {
    const values = form.getFieldsValue();

    const {
      name,
      type,
      order
    } = values;

    // 调用接口修改属性分类
    await updateClassifyAttr({
      data: {
        [selectId]: [id]
      }
    });

    const oldAttribute = attributes[rowIndex];

    const newAttribute = {
      ...oldAttribute,
      classifyId: selectId,
      type,
      order,
      name: oldAttribute.name.map((item, i) => {
        switch (i) {
          case 0:
            return { ...item, value: name.zh };
          case 1:
            return { ...item, value: name.en };
          case 2:
            return { ...item, value: name.ru };
          default:
            return item;
        }
      }),
    };

    updateAttribute(rowIndex, newAttribute);
  };

  const {
    visible,
    show,
    hide,
    onSubmit
  } = useDrawer(submitHook);

  const handleReset = () => form.resetFields();

  const handleRemove = () => {
    if (includeChildren) {
      return message.warning(t("model.editAttr.warning.includeChildren"));
    }

    removeAttribute(rowIndex);
  };

  const attributeName = `${id ? id + ":" : ""} ${
    name[nameLangMap(i18n.language)].value
  }`;

  const handleSelectChange = (value: any) => {
    const filter = classify.filter((v: any) => v.value === value);
    const now = filter[0] || {};

    selectId = now.id;
  }

  let defaultSelect = '';

  if (classify && classify.length > 0) {
    const filter = classify.filter((v: any) => v.id === classifyId);
    const now = filter[0] || {};

    defaultSelect = now.value;
  }

  const renderClassify = () => {
    if (classify && classify.length > 0) {
      return (
        <div className="model-classify-main">
          <div className="mcm-title">
            {t("model.imageAttrForm.attr")}
          </div>
          <Select
            defaultValue={defaultSelect}
            className="mcm-select"
            style={{width: 200}}
            onChange={handleSelectChange}
            options={classify}
          />
        </div>
      )
    }

    return null;
  }

  return (
    <div style={{ margin: "4px 0" }}>
      <span className="mr-10 attribute-atom attribute-type-color">
        <span role="button" onClick={show}>
          {attributeName}
        </span>
        <CloseOutlined
          className="close-btn type-close-btn"
          role="button"
          style={{ marginLeft: 5, marginRight: -5 }}
          onClick={handleRemove}
        />
      </span>
      <Drawer
        title={t("model.editAttr.title")}
        visible={visible}
        onClose={hide}
        destroyOnClose
        width={359}
        extra={
          <Space>
            <Button onClick={handleReset}>{t("model.editAttr.reset")}</Button>
            <Button type="primary" onClick={onSubmit}>
              {t("model.editAttr.submit")}
            </Button>
          </Space>
        }
      >
        <AttributeTypeForm
          form={form}
          disabled={includeChildren}
          initialValues={{
            order,
            type,
            name: { zh: name[0].value, en: name[1].value, ru: name[2].value },
          }}
          classify={renderClassify()}
        />
      </Drawer>
    </div>
  );
};

export default EditAttribute;
