import { Breadcrumb } from "antd";
import useStorage from "../../../stores/storage";

const PathBar = () => {
  const { history, setCurrentRef } = useStorage();

  return (
    <Breadcrumb separator=">">
      {history.map((item) => (
        <Breadcrumb.Item
          key={item.key}
          onClick={() => setCurrentRef(Number(item.key))}
        >
          <span style={{ cursor: "pointer" }}>{item.title}</span>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default PathBar;
