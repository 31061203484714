import { Form, Button, Space, Drawer } from "antd";

import { CloseOutlined } from "@ant-design/icons";

import AttributeTextForm from "./AttributeTextForm";
import AttributeImageForm from "./AttributeImageForm";

import useModel from "../../stores/model";
import useDrawer from "../../hooks/useDrawer";

import { useTranslation } from "react-i18next";

import { nameLangMap } from "../../i18n";

const { useForm } = Form;

const EditSubAttribute = ({
  rowIndex,
  colIndex,
  type,
  data
}: {
  rowIndex: number;
  colIndex: number;
  type: 0 | 1;
  data: any;
}) => {
  const [form] = useForm();

  const { id, name, path, src, order } = data;

  const { updateSubAttribute, removeSubAttribute, attributes } = useModel();

  const submitHook = () => {
    const values = form.getFieldsValue();

    const { name, image, order } = values;
    const { path, src } = image || { path: "", src: 0 };

    const oldAttribute = attributes[rowIndex].list[colIndex];

    const newAttribute = {
      ...oldAttribute,
      order,
      path,
      src,
      name: oldAttribute.name.map((item, i) => {
        switch (i) {
          case 0:
            return { ...item, value: name.zh };
          case 1:
            return { ...item, value: name.en };
          case 2:
            return { ...item, value: name.ru };
          default:
            return item;
        }
      }),
    };

    updateSubAttribute(rowIndex, colIndex, newAttribute); // 更新主数据
  };

  const {
    visible,
    show,
    hide,
    onSubmit
  } = useDrawer(submitHook);

  const handleReset = () => form.resetFields();

  const handleRemove = () => removeSubAttribute(rowIndex, colIndex);

  const { t, i18n } = useTranslation();

  let nameObj = name[nameLangMap(i18n.language)];

  if (!nameObj) {
    nameObj = name[0];
  }

  const value = nameObj ? nameObj.value : '';

  const subAttributeName = `${id ? id + ":" : ""} ${
    value
  }`;

  return (
    <div style={{ margin: "4px 0" }}>
      <span className="mr-10 attribute-atom attribute-item-color">
        <span role="button" onClick={show}>
          {subAttributeName}
        </span>
        <CloseOutlined
          className="close-btn item-close-btn"
          role="button"
          style={{ marginLeft: 5, marginRight: -5 }}
          onClick={handleRemove}
        />
      </span>
      {
        visible ? (
          <Drawer
            title={
              type === 0
                ? t("model.editSubAttr.text")
                : t("model.editSubAttr.image")
            }
            visible={visible}
            onClose={hide}
            destroyOnClose
            width={359}
            extra={
              <Space>
                <Button onClick={handleReset}>
                  {t("model.editSubAttr.reset")}
                </Button>
                <Button type="primary" onClick={onSubmit}>
                  {t("model.editSubAttr.submit")}
                </Button>
              </Space>
            }
          >
            <>
              {
                type === 0 ? (
                  <AttributeTextForm
                    form={form}
                    initialValues={{
                      order,
                      name: { zh: name[0].value, en: name[1].value, ru: name[2].value },
                    }}
                  />
                ) : (
                  <AttributeImageForm
                    form={form}
                    initialValues={{
                      order,
                      image: { path, src },
                      name: { zh: name[0].value, en: name[1].value, ru: name[2].value },
                    }}
                  />
                )
              }
            </>
          </Drawer>
        ) : null
      }
    </div>
  );
};

export default EditSubAttribute;
