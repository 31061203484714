import { useState, useEffect } from 'react';
import { Button, Drawer, Input, Spin, Popconfirm, Radio } from "antd";
import { useParams } from "react-router-dom";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useTranslation } from "react-i18next";

import useDrawer from "../../../hooks/useDrawer";
import useLoading from "../../../hooks/useLoading";

import useSpuInfo from '../../../stores/spuInfo';
import useSpuPoints from "../../../stores/spuPoints";

import './size.css';

const Size = () => {
  const [infoLoading, setInfoLoading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [label, setLabel] = useState([]);
  const [sizeInfoImage, setSizeInfoImage] = useState('');
  const [sizeIsDefault, setSizeIsDefault] = useState('');

  const { t } = useTranslation();
  const { xs } = useBreakpoint();
  const { id } = useParams();

  const {
    getSpuSizeInfo,
    putSpuSizeInfo
  } = useSpuPoints();

  const {
    fetch: fetchInfo
  } = useSpuInfo();

  const { visible, show, hide, setVisible } = useDrawer();

  const drawerWidth = xs ? 378 : 520;

  const getInfo = async (id: any) => {
    setInfoLoading(true);

    const res = await getSpuSizeInfo(id);

    const {
      sizeInfo,
      sizeInfoImage,
      sizeIsDefault
    } = res;

    setLabel(sizeInfo);
    setSizeInfoImage(sizeInfoImage);
    setSizeIsDefault(sizeIsDefault);

    setInfoLoading(false);
  }

  const handleSubmit = () => {
    let finalLabel: any = {};

    label.forEach((v: any) => {
      const {
        labelKey,
        labelValue
      } = v || {};

      finalLabel = {
        ...finalLabel,
        [`${labelKey}`]: labelValue
      }
    });

    putSpuSizeInfo(id, finalLabel, sizeInfoImage, sizeIsDefault);

    if (id) {
      fetchInfo(id, false);
    }

    setVisible(false);

    setLabel([]);
    setSizeInfoImage('');
    setSizeIsDefault('');
  };

  const handleAddLabelClick = () => {
    const finalLabel: any = [
      ...label,
      {
        labelKey: '',
        labelValue: [{
          name: '',
          value: ''
        }]
      }
    ];

    setLabel(finalLabel);
  }

  const handleAddSizeClick = (i: any) => {
    const finalLabel = label;

    const obj: any = finalLabel[i];

    const labelValue: any = obj.labelValue;

    const finalLabelValue: any = [
      ...labelValue,
      {
        name: '',
        value: '',
      }
    ];

    obj.labelValue = finalLabelValue;

    (finalLabel[i] as any) = obj;

    const finalU = update + 1;

    setUpdate(finalU);
    setLabel(finalLabel);
  }

  const handleLabelKeyChange = (e: any, i: any) => {
    const value = e.target.value;
    const finalLabel = label;

    const obj: any = finalLabel[i];

    obj.labelKey = value;

    (finalLabel[i] as any) = obj;

    const finalU = update + 1;

    setUpdate(finalU);
    setLabel(finalLabel);
  }

  const handleNameKeyChange = (e: any, i: any, index: any) => {
    const value = e.target.value;
    const finalLabel = label;

    const obj: any = finalLabel[i];

    const {
      labelValue
    } = obj || {};

    const obj2: any = labelValue[index];

    obj2.name = value;

    (labelValue[index] as any) = obj2;
    (finalLabel[i] as any) = obj;

    const finalU = update + 1;

    setUpdate(finalU);
    setLabel(finalLabel);
  }

  const handleValueKeyChange = (e: any, i: any, index: any) => {
    const value = e.target.value;
    const finalLabel = label;

    const obj: any = finalLabel[i];

    const {
      labelValue
    } = obj || {};

    const obj2: any = labelValue[index];

    obj2.value = value;

    (labelValue[index] as any) = obj2;
    (finalLabel[i] as any) = obj;

    const finalU = update + 1;

    setUpdate(finalU);
    setLabel(finalLabel);
  }

  const handleSizeInfoImageChange = (e: any) => {
    const value = e.target.value;

    setSizeInfoImage(value);
  }

  const handleDeleteLabel = async (i: any) => {
    const finalLabel = label;

    const filterLabel = finalLabel.filter((v: any, index: any) => index !== i);

    const paramLabel: any = {};

    filterLabel.forEach((v: any) => {
      const {
        labelKey,
        labelValue
      } = v;

      paramLabel[labelKey] = labelValue;
    });

    await putSpuSizeInfo(id, paramLabel, sizeInfoImage, sizeIsDefault);

    setLabel(filterLabel);

    if (id) {
      fetchInfo(id, false);
    }
  }

  const handleDeleteSize = async (i: any, index: any) => {
    const finalLabel: any = label;

    const nowLabel: any = finalLabel[i] || {};

    const {
      labelValue
    } = nowLabel || {};

    if (labelValue && labelValue.length > 0) {
      const filterLabelValue = labelValue.filter((v: any, ni: any) => ni !== index);

      finalLabel[i].labelValue = filterLabelValue;
    }

    const finalU = update + 1;

    const paramLabel: any = {};

    finalLabel.forEach((v: any) => {
      const {
        labelKey,
        labelValue
      } = v;

      paramLabel[labelKey] = labelValue;
    });

    await putSpuSizeInfo(id, paramLabel, sizeInfoImage, sizeIsDefault);
    
    setUpdate(finalU);
    setLabel(finalLabel);

    if (id) {
      fetchInfo(id, false);
    }
  }

  const handleRadioClick = (key: any) => {
    setSizeIsDefault(key);
  }

  useEffect(() => {
    if (id && visible) {
      getInfo(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, visible]);

  const [onSubmit, loading] = useLoading(handleSubmit);

  return (
    <>
      <Button type="default" onClick={show}>
        {t("spuChart.tools.size")}
      </Button>
      <Drawer
        title={t("spuChart.tools.size")}
        onClose={hide}
        visible={visible}
        destroyOnClose
        width={drawerWidth}
        className="spu-size-drawer"
        extra={
          <Button
            type="primary"
            onClick={onSubmit}
            loading={loading}
          >
            {t("spuChart.tools.customDrawer.submit")}
          </Button>
        }
      >
        <div
          className="sta-size"
          style={{
            width: "100%",
            height: "100%",
            overflowY: "scroll"
          }}
        >
          <div className='sta-update'>{update}</div>
          {
            infoLoading && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Spin />
              </div>
            )
          }
          {
            !infoLoading && (
              <div className="sta-size-info">
                <div
                  className="sta-si-label-div"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: "24px",
                    paddingBottom: "24px",
                    borderBottom: "1px solid #dddddd"
                  }}
                >
                  <div
                    className="sta-sild-title"
                    style={{
                      display: "inline-block",
                      fontSize: "16px",
                      flexShrink: 0
                    }}
                  >
                    {t("spuInfo.label.sizeImage")}:
                  </div>
                  <div
                    className="sta-slid-label"
                    style={{
                      display: "inline-block",
                      marginLeft: "12px",
                      width: '100%'
                    }}
                  >
                    <Input
                      placeholder={t("spuInfo.label.sizeImagePlaceHolder")}
                      value={sizeInfoImage}
                      onChange={(e: any) => handleSizeInfoImageChange(e)}
                    />
                  </div>
                </div>
                {
                  (label && label.length > 0) && label.map((v: any, i: any) => {
                    const {
                      labelKey,
                      labelValue
                    } = v;

                    const finalValue = labelValue || []

                    return (
                      <div
                        key={i}
                        className="sta-si-list"
                      >
                        <Popconfirm
                          title={t("spuChart.size.deleteLabel")}
                          okText={t("confirm.ok")}
                          cancelText={t("confirm.cancel")}
                          onConfirm={() => handleDeleteLabel(i)}
                        >
                          <div className="sta-sil-delete" />
                        </Popconfirm>
                        <div
                          className="sta-sil-name"
                          style={{
                            position: 'relative'
                          }}
                        >
                          <div className="sta-siln-title">
                            {t("spuChart.size.labelName")}:
                          </div>
                          <div className="sta-siln-label">
                            <Input
                              placeholder={t("spuChart.size.labelNamePlaceHodler")}
                              value={labelKey}
                              onChange={(e: any) => handleLabelKeyChange(e, i)}
                            />
                          </div>
                          <Radio
                            className="sta-slin-radio"
                            checked={labelKey && labelKey === sizeIsDefault}
                            onChange={() => handleRadioClick(labelKey)}
                            style={{
                              marginLeft: '30px'
                            }}
                            disabled={!labelKey}
                          />
                        </div>
                        <div className="sta-sil-value">
                          <div className="sta-silv-title">
                            {t("spuChart.size.labelValue")}:
                          </div>
                          <div className="sta-silv-label">
                            {
                              finalValue.map((f: any, index: any) => {
                                const {
                                  name,
                                  value
                                } = f;

                                return (
                                  <div
                                    key={index}
                                    className="sta-silvl-list"
                                  >
                                    <Popconfirm
                                      title={t("spuChart.size.deleteSize")}
                                      okText={t("confirm.ok")}
                                      cancelText={t("confirm.cancel")}
                                      onConfirm={() => handleDeleteSize(i, index)}
                                    >
                                      <div className="sta-silvl-delete" />
                                    </Popconfirm>
                                    <div className="sta-slivl-num">
                                      {index + 1}:
                                    </div>
                                    <div className="sta-slivl-main">
                                      <div className="sta-silvll-list">
                                        <div className="sta-silvll-title">
                                          {t("spuChart.size.sizeName")}:
                                        </div>
                                        <div className="sta-silvll-label">
                                          <Input
                                            placeholder={t("spuChart.size.sizeNamePlaceHodler")}
                                            value={name}
                                            onChange={(e: any) => handleNameKeyChange(e, i, index)}
                                          />
                                        </div>
                                      </div>
                                      <div className="sta-silvll-list">
                                        <div className="sta-silvll-title">
                                          {t("spuChart.size.sizeValue")}:
                                        </div>
                                        <div className="sta-silvll-label">
                                          <Input
                                            placeholder={t("spuChart.size.sizeValuePlaceHodler")}
                                            value={value}
                                            onChange={(e: any) => handleValueKeyChange(e, i, index)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                            <div className="sta-silvll-add">
                              <Button
                                type="primary"
                                className='sta-ad-button'
                                onClick={() => handleAddSizeClick(i)}
                              >
                                {t("spuChart.size.addSize")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                <div className='sta-add-data'>
                  <Button
                    type="primary"
                    className='sta-ad-button'
                    onClick={handleAddLabelClick}
                  >
                    {t("spuChart.size.add")}
                  </Button>
                </div>
              </div>
            )
          }
        </div>
      </Drawer>
    </>
  );
};

export default Size;
