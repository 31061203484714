import axios from "../libs/axios";

// 删除分类信息
const catInfoDel = async (params: {catID: number}) => await axios.post("/cat/catInfoDel", params);

// 获取分类信息
const catInfo = async (params: {catID: string, first?: string}) => await axios.get("/cat/catInfo", { params });

interface catInfoParams {
  pid: number,
  image: number,
  catID: number,
  orderIndex: number,
  catePriceNum: string,
  nameLang: any[],
  contentLang: any[],
}
// SPU 分类信息提交
const catInfoPut = async (params: catInfoParams) => await axios.post("/cat/catInfoPut", params);


const classifyServices = {
  catInfoDel,
  catInfo,
  catInfoPut
};

export default classifyServices;