import { PageHeader, Tabs } from "antd";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseLayout from "../../components/BaseLayout";
import useSpuPoints from "../../stores/spuPoints";
import useSpuInfo from "../../stores/spuInfo";
import Performance from "./Performance/index";
import MultiSpeed from "./MultiSpeed";
import SeriesParallel from "./SeriesParallel";
import Tools from "./Tools";
import Info from "./Description";
import useSpuPdfParam from "../../stores/spuPdfParam";
import PerformanceSpeed from "./PerformanceSpeed";

// spu 列表

const Spu = () => {
  const { t } = useTranslation();
  const navgiate = useNavigate();
  
  const { id, tab } = useParams() as { id: string; tab: string };
  const { fetch: fetchPoints, name, dataset, dataSource } = useSpuPoints();
  const { fetch: fetchInfo, spu } = useSpuInfo();
  const { fetch: fetchPdfParameter } = useSpuPdfParam();

  const title = name || spu || t("route.chart");
  const loaded = dataset !== undefined && dataSource !== undefined;

  const handleTabChange = (key: string) => {
    if (!key) {
      return;
    }

    navgiate(`/spus/${id}/${key}`);
  };

  useEffect(() => {
    fetchInfo(id);
    fetchPdfParameter(id);
    fetchPoints(id);
  }, [fetchPoints, fetchInfo, fetchPdfParameter, id]);

  return (
    <BaseLayout title={title}>
      <PageHeader
        title={title}
        style={{ padding: "16px 0" }}
        extra={<Tools loaded={loaded} />}
      />
      <Tabs
        defaultActiveKey={tab}
        size="large"
        onChange={handleTabChange}
        destroyInactiveTabPane={false}
      >
        <Tabs.TabPane
          tab={t("spuChart.tab.performance")}
          key="performance"
          forceRender={true}
        >
          <Performance />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t("spuChart.tab.performanceAndSpeed")}
          key="performanceSpeed"
        >
          <PerformanceSpeed />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("spuChart.tab.speed")} key="multiSpeed">
          <MultiSpeed />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t("spuChart.tab.seriesParallel")}
          key="seriesParallel"
        >
          <SeriesParallel />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("spuChart.tab.description")} key="info">
          <Info />
        </Tabs.TabPane>
      </Tabs>
    </BaseLayout>
  );
};

export default Spu;
