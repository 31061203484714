import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Spin, notification } from "antd";
import { LineSeriesOption } from "echarts";

import Chart, { EChartsOption } from "../../../components/Chart";

import { EChartsType } from "echarts/core";

import spuServices from "../../../services/spu";

import useLoading from "../../../hooks/useLoading";

import { convertEFF1ToEFF2, createK, createQP, filterCloseArray, findIntersectionPoint, genRegressionModal, isSpuPointNotEmpty, powerFormula, precision, stockMap, zip } from "../../../utils";

import { ProccessedSpuData, SpuFullData, Range, } from "../../../types";

import './SelectionChart.css';

const createWorkPoint = (q: number, h: number, spu: ProccessedSpuData) => {
  // 1. find intersection point
  if (!spu.QH || !spu.QEFF) return spu;
  const intersectionPoint = findIntersectionPoint(q, h, spu.QH?.equation);
  // 2. create the value of k
  if (!intersectionPoint) return spu;
  const k = createK(q, intersectionPoint[0]);
  // 3. use k to calculate eff/p/npsh?
  const eff = convertEFF1ToEFF2(spu.QEFF, intersectionPoint[0], k);
  const p = powerFormula([q, h], eff / 100);
  // 4. return new object
  if (spu.QNPSH) {
    const npsh = spu.QNPSH.predict(q)[1];

    return {
      ...spu,
      q,
      h,
      eff: precision(eff, 2),
      p: precision(p, 2),
      npsh: precision(npsh, 2),
    };
  }

  return {
    ...spu,
    q,
    h,
    eff: precision(eff, 2),
    p: precision(p, 2),
  };
};

const isPolyIntersected = (
  q: number,
  h: number,
  toleranceStart: number,
  toleranceEnd: number,
  spuEquation: number[],
  start: number,
  end: number
) => {
  const auxEquation = [h / q ** 2, 0, 0];
  const [a, b, c] = auxEquation.map((e, i) => e - spuEquation[i]);
  const delta = b ** 2 - 4 * a * c;
  if (delta < 0) return false;

  const x1 = (-b + Math.sqrt(delta)) / (2 * a);
  const x2 = (-b - Math.sqrt(delta)) / (2 * a);
  const x = Math.max(x1, x2);
  const r1 = { min: start, max: end };
  const r2 = { min: q * toleranceStart, max: q * toleranceEnd };

  return x >= r1.min && x <= r1.max && x >= r2.min && x <= r2.max;
};

const isRangeOverlap = (range: Range, aux: Range) => {
  const mostLeft = Math.min(range.min, range.max, aux.min, aux.max);
  const mostRight = Math.max(range.min, range.max, aux.min, aux.max);
  const rangeLength = range.max - range.min;
  const auxLength = aux.max - aux.min;

  return mostRight - mostLeft <= rangeLength + auxLength;
};

const selectionProccess = (
  q: number,
  h: number,
  toleranceStart: number,
  toleranceEnd: number,
  results: SpuFullData[]
) => {
  if (!results) return [];
  if (results.length === 0) return results;

  let newResults: ProccessedSpuData[] = results;
  // 0. 过滤掉没有数据点的Spu
  newResults = newResults.filter(isSpuPointNotEmpty);
  // 1. 根据区间长度过滤一部分数据
  newResults = newResults.filter((spu: any) =>
    isRangeOverlap(
      { min: spu.start, max: spu.end },
      { min: q * (toleranceStart / 100), max: q * (toleranceEnd / 100) }
    )
  );
  // console.log("after range overlap", newResults);
  // 2. 获取过滤后的完整数据
  // 3. 根据矩形区域过滤一部分数据
  // newResults = newResults.filter((spu) =>
  //   isRectangleOverlap(q, h, {
  //     p: findStartPoint(spu.Q as number[], spu.H as number[], spu.start),
  //     q: findEndPoint(spu.Q as number[], spu.H as number[], spu.end),
  //   })
  // );
  // console.log("after rectangle overlap", newResults);
  // 4. 对剩余的数据进行线性回归
  // 5. 使用交点公式进行最后的过滤
  newResults = newResults
    .map((spu) => {
      const QH = genRegressionModal(zip(spu.Q as any, spu.H as any));
      const QEFF = genRegressionModal(zip(spu.Q as any, spu.EFF as any));
      const QP = createQP(QH.points, QEFF.points);

      if (spu.NPSH?.length) {
        return {
          ...spu,
          QH,
          QEFF,
          QP,
          QNPSH: genRegressionModal(zip(spu.Q as any, spu.NPSH as any)),
        };
      }

      return {
        ...spu,
        QH,
        QEFF,
        QP,
      };
    })
    .filter((spu) =>
      isPolyIntersected(
        q,
        h,
        toleranceStart / 100,
        toleranceEnd / 100,
        spu.QH.equation,
        spu.start,
        spu.end
      )
    )
    .map((spu) => createWorkPoint(q, h, spu));

  newResults = newResults.filter((spu: any) => {
    const {
      p,
      pLimit
    } = spu;

    let flag: boolean = false;

    if (pLimit && pLimit > 0) {
      if (pLimit >= p * 1.1 && pLimit <= p * 2) {
        flag = true;
      }
    }

    if (pLimit === '0' || pLimit === 0) {
      flag = true;
    }

    return flag;
  });

  newResults = newResults.sort((a: any, b: any) => b.eff - a.eff);

  return newResults;
};

const parseFullSpus = (spus: any): SpuFullData[] => {
  return spus.map((item: any) => {
    let QTotal: any = 0;
    let HTotal: any = 0;

    if (item.q_coordinates && item.q_coordinates.length > 0) {
      QTotal = item.q_coordinates.reduce((p: any, v: any) => p + v);
    }

    if (item.h_coordinates && item.h_coordinates.length > 0) {
      HTotal = item.h_coordinates.reduce((p: any, v: any) => p + v);
    }

    const final: any = {
      id: item.id,
      spu: item.spu,
      start: item.valid_start || item.start,
      end: item.valid_end || item.end,
      Q: item.q_coordinates,
      H: item.h_coordinates,
      EFF: item.eff_coordinates,
      NPSH: item.npsh_coordinates,
      pLimit: item.p_limit,
      name: item.spu_name,
      speed: item.speed,
      freq: item.freq,
      QTotal,
      HTotal
    };

    return final;
  });
};

let first: any = false;
let filteredSpus: any = [];
let allSelectionData: any = [];

const SelectionChart = ({
  q,
  h,
  stock,
  selected,
  toleranceStart,
  toleranceEnd,
  handleSubmit,
  handleHeadChange,
  handleQuantityChange
}: any) => {
  const { t } = useTranslation();
  
  const [total, setTotal] = useState(0);
  const [mate, setMate] = useState(0);
  const [drawLoading, setDrawLoading] = useState(false);

  const initialOption: EChartsOption = {
    xAxis: [
      {
        name: "m³/h",
        type: "value",
        min: 0,
        max: 300,
        interval: 60,
        silent: true
      }
    ],
    yAxis: [
      {
        name: "H(m)",
        type: "value",
        min: 0,
        max: 500,
        interval: 50,
        silent: true
      }
    ],
  };

  const isSelected = selected.length > 0;

  const [option, setOption] = useState(initialOption);

  const drawLine = () => {
    setDrawLoading(true);

    let optionQ: any = 0;
    let optionH: any = 0;
    let QH: any = [];

    allSelectionData.forEach((v: any) => {
      if (v) {
        const {
          Q,
          H
        } = v;
  
        const finalQ = Q[Q.length - 1];
        const finalH = H[H.length - 1];
  
        if (optionQ < finalQ) {
          optionQ = finalQ;
        }
  
        if (optionH < finalH) {
          optionH = finalH;
        }
  
        const qh = genRegressionModal(zip(Q, H), true, isSelected);
  
        QH.push(qh);
      }
    });

    let initialSeries: LineSeriesOption[] = [];

    if (selected.length > 0) {
      // 有过滤的值 或者 q和h不为空 [q和h不为空基本就是有筛选]
      if (filteredSpus.length > 0 || (q && h)) {
        QH.forEach((v: any) => {
          const {
            dummyPoints
          } = v;
    
          initialSeries = [
            ...initialSeries,
            {
              name: "QH1",
              type: "line",
              smooth: true, // 是否平滑
              symbolSize: 0, // 标记的大小
              lineStyle: {
                width: 0.4
              },
              data: dummyPoints,
              color: "#cccccc",
              animation: false // 关闭动画
            }
          ]
        });
  
        filteredSpus.forEach((v: any) => {
          const {
            Q,
            H
          } = v;
  
          const qh = genRegressionModal(zip(Q, H), true, isSelected);
  
          const {
            dummyPoints
          } = qh;
    
          initialSeries = [
            ...initialSeries,
            {
              name: "QH1",
              type: "line",
              smooth: true, // 是否平滑
              symbolSize: 0, // 标记的大小
              lineStyle: {
                width: 0.8
              },
              data: dummyPoints,
              color: "#7bbfff",
              animation: false // 关闭动画
            }
          ]
        });
      } else {
        QH.forEach((v: any) => {
          const {
            dummyPoints
          } = v;
    
          initialSeries = [
            ...initialSeries,
            {
              name: "QH1",
              type: "line",
              smooth: true, // 是否平滑
              symbolSize: 0, // 标记的大小
              lineStyle: {
                width: 0.4
              },
              data: dummyPoints,
              color: "#7bbfff",
              animation: false // 关闭动画
            }
          ]
        });
      }
    }

    let newOption: any = {
      ...initialOption,
      yAxis: [
        // H
        {
          name: "H(m)",
          type: "value",
          splitNumber: 10,
          silent: true,
          triggerEvent: true,
          axisPointer: {
            show: true,
            status: 'show',
            label: {
              formatter: (v: any) => {
                const {
                  value
                } = v;

                const finalValue = Number(value);

                if (isSelected) {
                  return parseFloat((Math.pow(value, 3)).toFixed(2));
                } else {
                  return parseFloat((Math.pow(finalValue, 6)).toFixed(4));
                }
              }
            }
          },
          axisLabel: {
            formatter: (value: any) => {
              if (isSelected) {
                return parseFloat((Math.pow(value, 3)).toFixed(2));
              } else {
                return parseFloat((Math.pow(value, 6)).toFixed(4));
              }
            }
          }
        }
      ],
      xAxis: [
        {
          name: "m³/h",
          type: "value",
          splitNumber: 8,
          silent: true,
          triggerEvent: true,
          axisPointer: {
            show: true,
            status: 'show',
            label: {
              formatter: (v: any) => {
                const {
                  value
                } = v;

                const finalValue = Number(value);

                return parseFloat((finalValue * finalValue * finalValue * finalValue).toFixed(2));
              }
            }
          },
          axisLabel: {
            formatter: (value: any) => {
              return parseFloat((value * value * value * value).toFixed(2));
            }
          }
        }
      ],
      series: initialSeries
    };

    if (QH.length === 0) {
      newOption = {
        ...initialOption,
        yAxis: [
          // H
          {
            name: "H(m)",
            type: "value",
            min: 0,
            max: 500,
            splitNumber: 10
          }
        ],
        xAxis: [
          {
            name: "m³/h",
            type: "value",
            min: 0,
            max: 100,
            splitNumber: 8
          }
        ],
        series: initialSeries
      }
    }

    setDrawLoading(false);
    setOption(newOption);
  }

  const getFilterList = async() => {
    if (q && h) {
      const res = await spuServices.getSelection({
        page: 1,
        limit: 2000,
        full: 0,
        q,
        stock: stockMap[stock],
        cat: selected.join(",") || undefined,
      });
  
      const {
        spus
      } = res.data.response;
  
      const processedSpus = parseFullSpus(spus);

      filteredSpus = selectionProccess(
        q,
        h,
        toleranceStart,
        toleranceEnd,
        processedSpus
      );

      setMate(filteredSpus.length);
    }
  }

  const getSpuList = async () => {
    const res = await spuServices.getSelection({
      page: 1,
      limit: 10000,
      full: 0,
      cat: selected.join(",") || undefined
    });

    const {
      spus
    } = res.data.response;

    const processedSpus = parseFullSpus(spus);

    // 先过滤空的
    allSelectionData = processedSpus.filter(isSpuPointNotEmpty);

    // 全部渲染数据太多了, 感觉要想个办法大概过滤一下 最多1000条就够了
    const length = allSelectionData.length;

    setTotal(length);

    if (length > 500) {
      // 一些基本重复的过滤掉，特殊还是得保留
      allSelectionData = filterCloseArray(allSelectionData);
    }

    if (selected.length === 0) { // 没有选择分类，不需要显示 [二次过滤先去掉了]
      allSelectionData = [];

      // allSelectionData = filterCloseTwoArray(allSelectionData);
    }
  };

  const drawLineFirst = async () => {
    await getSpuList();
    await getFilterList();

    drawLine();
    first = false;
  }

  const drawLineTwo = async () => {
    await getFilterList();

    drawLine();
  }

  const [onChange, loading] = useLoading(drawLineFirst);
  const [onChangeTwo, loadingTwo] = useLoading(drawLineTwo);

  useEffect(() => {
    first = true;

    onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(selected)
  ]);

  useEffect(() => {
    if (!first) {
      onChangeTwo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    q, h, toleranceStart, toleranceEnd, stock
  ]);

  const handleChartChange = (instance: EChartsType) => {
    // const result = instance.getDataURL(exportTemplate as any);
    // 当 excludeComponents 执行后，会导致图表上对应的组件暂时消失。
    // 这里强制执行一次，重新让组件显示出来.
    instance.getDataURL();
  };

  const handleChartClick = (params: any, chart: any) => {
    if (selected.length === 0) {
      notification.error({
        message: t('spuSelection.choose')
      });

      return;
    }

    const pixel = [params.offsetX, params.offsetY];

    if (chart && chart.containPixel({seriesIndex: 0}, pixel)) {
      const xAxisPointer = chart.getOption().xAxis[0].axisPointer;
      const yAxisPointer = chart.getOption().yAxis[0].axisPointer;

      const {
        value: xValue
      } = xAxisPointer;

      const {
        value: yValue
      } = yAxisPointer;
      
      const finalXValue = parseFloat(Math.pow(xValue, 4).toFixed(2));

      let finalYValue: any = 0;

      if (isSelected) {
        finalYValue = parseFloat(Math.pow(yValue, 3).toFixed(2));
      } else {
        finalYValue = parseFloat(Math.pow(yValue, 6).toFixed(2));
      }

      if (handleQuantityChange) {
        handleQuantityChange({
          target: {
            value: finalXValue
          }
        });
      }

      if (handleHeadChange) {
        handleHeadChange({
          target: {
            value: finalYValue
          }
        });
      }

      if (handleSubmit) {
        handleSubmit({}, 'chart');
      }
    }
  }

  return (
    <div className='as-selection-chart'>
      {
        (loading || loadingTwo || drawLoading) && (
          <div className='as-sc-loading'>
            <Spin
              spinning={loading || loadingTwo || drawLoading}
              className='as-scl-spin'
            />
          </div>
        )
      }
      <Chart
        option={option}
        notMerge
        className='selectrion-chart'
        onChange={handleChartChange} // 图表变换时的回调函数
        handleChartClick={handleChartClick}
      />
      <div className='as-sc-total'>
        {
          (q && h) ? (
            <span className='as-sct-span'>
              {t("spuSelection.mate")}: {mate}/{total}
            </span>
          ) : (
            <span className='as-sct-span'>
              {t("spuSelection.total")}: {total}
            </span>
          )
        }
      </div>
    </div>
  );
};

export default SelectionChart;