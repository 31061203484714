import { FolderOutlined } from "@ant-design/icons";
import { Spin, Tree } from "antd";
import { Key, useEffect, useState } from "react";
import useStorage from "../../stores/storage";

const { DirectoryTree } = Tree;

interface FileTreeProps {
  onChange?: (id: number) => void;
}

const FileTree = ({ onChange }: FileTreeProps) => {
  const { pathList, setCurrentRef, currentRef, loadingStructure } =
    useStorage();
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([String(currentRef)]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([String(currentRef)]);

  const handleExpand = (newExpandedKeys: Key[]) =>
    setExpandedKeys(newExpandedKeys);

  const handleSelect = (newSelectedKeys: Key[]) => {
    const [path] = newSelectedKeys;
    if (path) {
      if (onChange) {
        onChange(parseInt(path as string));
      } else {
        setCurrentRef(parseInt(path as string));
      }
      setSelectedKeys(newSelectedKeys);
    }
  };

  useEffect(() => {
    if (currentRef) {
      if (!expandedKeys.includes(String(currentRef))) {
        const newExpandedKeys = [...expandedKeys, String(currentRef)];
        setExpandedKeys(newExpandedKeys);
      }
      setSelectedKeys([String(currentRef)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRef]);

  if (loadingStructure) {
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <DirectoryTree
      icon={<FolderOutlined />}
      blockNode={true}
      autoExpandParent={false}
      selectedKeys={selectedKeys}
      expandedKeys={expandedKeys}
      onSelect={handleSelect}
      onExpand={handleExpand}
      treeData={pathList}
      expandAction="doubleClick"
    />
  );
};

export default FileTree;
