import React, { useContext } from "react";
import {
  Table,
  Form,
  Popconfirm,
  Button,
  InputNumber,
  ConfigProvider,
} from "antd";
import { FormInstance } from "antd/lib";
import { PlusOutlined } from "@ant-design/icons";
import { langMap } from "../i18n";
import { useTranslation } from "react-i18next";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

export type CellDataType = number | null | undefined;

export interface RowDataType {
  key: React.Key;
  q: CellDataType;
  h: CellDataType;
  eff: CellDataType;
  npsh: CellDataType;
}

interface EditableRowProps {
  index: number;
}

const EditableRow = ({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof RowDataType;
  record: RowDataType;
  handleSave: (record: RowDataType) => void;
  handleAdd: () => void;
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleAdd,
  ...props
}: EditableCellProps) => {
  const form = useContext(EditableContext)!;
  if (record) form.setFieldsValue({ [dataIndex]: record[dataIndex] });

  const save = async () => {
    try {
      const values = await form.validateFields();

      handleSave({ ...record, ...values });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <td {...props}>
      {editable ? (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <InputNumber
            onChange={save}
            onBlur={save}
            onPressEnter={() => handleAdd()}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap">{children}</div>
      )}
    </td>
  );
};

const generateColumns =
  (columns: any[]) => (onCol: (col: any) => any) => (action?: any) => {
    if (action) return [...columns, { ...action }].map(onCol);
    return columns.map(onCol);
  };

const EditableTable = ({ value = [], onChange, append = true }: any) => {
  const { t, i18n } = useTranslation();

  const handleDelete = (key: React.Key) => {
    if (!append) return;

    onChange(value.filter((data: any) => data.key !== key));
  };

  const handleAdd = () => {
    if (!append) return;

    onChange([
      ...value,
      {
        key: String(value.length + 1),
        q: null,
        h: null,
        eff: null,
        npsh: null,
      },
    ]);
  };

  const handleSave = (row: RowDataType) => {
    const newData = [...value];
    onChange(
      newData.map((item) => {
        if (row.key === item.key) {
          return {
            key: row.key,
            q: row.q,
            h: row.h,
            eff: row.eff,
            npsh: row.npsh,
          };
        }
        return item;
      })
    );
  };

  const action = {
    title: "Action",
    dataIndex: "action",
    width: 70,
    fixed: "right" as const,
    render: (_: any, record: { key: React.Key }) => (
      <Popconfirm
        title={t("spuForm.table.delete.confirm")}
        onConfirm={() => handleDelete(record.key)}
      >
        <Button type="link">{t("spuForm.table.delete.button")}</Button>
      </Popconfirm>
    ),
  };

  const onCol = (col: any) => ({
    ...col,
    onCell: (record: RowDataType) => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave,
      handleAdd,
    }),
  });

  const baseColumns = [
    {
      title: "Q",
      dataIndex: "q",
      width: 100,
      editable: true,
    },
    {
      title: "H",
      dataIndex: "h",
      width: 100,
      editable: true,
    },
    {
      title: "EFF",
      dataIndex: "eff",
      width: 100,
      editable: true,
    },
    {
      title: "NPSHr",
      dataIndex: "npsh",
      width: 100,
      editable: true,
    },
  ];

  const tableColumns = generateColumns(baseColumns)(onCol);
  const columns = append ? tableColumns(action) : tableColumns();

  return (
    <>
      <ConfigProvider locale={langMap(i18n.language)}>
        <Table
          dataSource={value}
          columns={columns}
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          bordered={false}
          pagination={false}
          scroll={{ x: 325 }}
          size="small"
        />
      </ConfigProvider>
      {append && (
        <Button
          type="dashed"
          onClick={handleAdd}
          style={{ marginTop: "10px", width: "100%" }}
        >
          <PlusOutlined />
        </Button>
      )}
    </>
  );
};

export default EditableTable;
