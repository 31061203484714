import { Button, Form, Input, InputNumber, Radio, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;

interface PerformanceFormProps {
  style?: object;
  onFinish: (t: object) => void;
  onReset: () => void;
  form: any;
  type: boolean;
}

interface PerformanceFormItemProps {
  label: string;
  name: string;
  units: string[];
  readOnly?: boolean;
  precision?: number;
}

const PerformanceFormItem = ({
  label,
  name,
  units,
  readOnly,
  precision,
}: PerformanceFormItemProps) => {
  return (
    <Form.Item label={label} required={!readOnly}>
      <Input.Group compact>
        <Form.Item noStyle name={name}>
          <InputNumber
            style={{ width: "80%" }}
            readOnly={readOnly}
            precision={precision !== undefined ? precision : 2}
          />
        </Form.Item>
        <Form.Item noStyle name={`${name}Unit`}>
          <Select style={{ width: "20%" }} disabled>
            {units.map((unit) => (
              <Option key={unit} value={unit}>
                {unit}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
};

const PerformanceForm = ({
  style,
  onFinish,
  onReset,
  form,
  type,
}: PerformanceFormProps) => {
  const { t } = useTranslation();

  return (
    <Form
      name="settings"
      layout="vertical"
      style={style}
      onFinish={onFinish}
      form={form}
      initialValues={{
        type: 0,
        quantityUnit: "m³/h",
        headUnit: "m",
        diameterUnit: "mm",
        speedUnit: "rpm",
        frequencyUnit: "hz",
        powerUnit: "kw",
        npshUnit: "m",
        effUnit: "%",
      }}
    >
      <Form.Item label={t("chart.formula")} name="type">
        <Radio.Group>
          <Radio.Button value={0}>{t("spuForm.formula.select.one")}</Radio.Button>
          <Radio.Button value={1} disabled={!type}>
            {t("spuForm.formula.select.two")}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <PerformanceFormItem
        label="Q"
        name="quantity"
        units={["m³/h"]}
        precision={1}
      />
      <PerformanceFormItem label="H" name="head" units={["m"]} precision={1} />
      <Form.Item label={t("chart.sg")} name="sg">
        <InputNumber
          style={{ width: "100%" }}
          precision={2}
          min={0.2}
          max={5}
        />
      </Form.Item>
      {form.getFieldValue("diameter") !== null && (
        <PerformanceFormItem
          label={t("chart.diameter")}
          name="diameter"
          units={["mm"]}
          readOnly
          precision={0}
        />
      )}
      <PerformanceFormItem
        label={t("chart.speed")}
        name="speed"
        units={["rpm"]}
        readOnly
        precision={0}
      />
      <PerformanceFormItem
        label={t("chart.frequency")}
        name="frequency"
        units={["hz"]}
        readOnly
      />
      <PerformanceFormItem label="P" name="power" units={["kw"]} readOnly />
      <PerformanceFormItem label="NPSHr" name="npsh" units={["m"]} readOnly />
      <PerformanceFormItem
        label={t("chart.efficiency")}
        name="eff"
        units={["%"]}
        readOnly
      />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t("chart.submit")}
        </Button>
        <Button type="ghost" style={{ marginLeft: "10px" }} onClick={onReset}>
          {t("chart.clear")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PerformanceForm;
