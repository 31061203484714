import { useState } from "react";
import { Button, Modal, Upload, message } from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../libs/axios";
import useAuth from "../../../hooks/useAuth";
import useModal from "../../../hooks/useModal";
import useStorage from "../../../stores/storage";
import { useTranslation } from "react-i18next";

const FileUpload = () => {
  const { t } = useTranslation();
  const { currentRef, fetchCurrentDirectory } = useStorage();
  const { onCancel, visible, show } = useModal();
  const { auth, isAdmin } = useAuth();
  const modalProps = { onCancel, visible };
  const [uploading, setUploading] = useState(false);

  return (
    <>
      <Button type="primary" icon={<UploadOutlined />} onClick={show}>
        {t("fileManager.tool.upload.button")}
      </Button>
      <Modal
        title={t("fileManager.tool.upload.title")}
        centered
        footer={false}
        {...modalProps}
      >
        {auth && isAdmin && (
          <Upload
            name="file"
            listType="picture-card"
            action={`${BASE_URL}/image/insert`}
            headers={{ authorization: `Bearer ${auth.token}` }}
            accept="image/jpeg, image/png, image/jpg, image/gif, image/svg"
            onChange={(info: any) => {
              if (info.file.status === "uploading") {
                setUploading(true);
              }
              if (info.file.status === "uploading" && !uploading) {
                setUploading(true);
              }
              if (info.file.status === "done") {
                message.success(t("fileManager.tool.upload.success"));
                fetchCurrentDirectory();
              }
              if (info.file.status === "error") {
                message.error(t("fileManager.tool.upload.failed"));
              }
            }}
            maxCount={9}
            data={{ pid: currentRef }}
            multiple
          >
            <Button type="text" icon={<PlusOutlined />} />
          </Upload>
        )}
      </Modal>
    </>
  );
};

export default FileUpload;
