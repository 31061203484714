export interface FileTreeNode {
  key: string;
  title: string;
  isLeaf?: boolean;
  children?: FileTreeNode[];
}

const getNodePath = (
  treeNode: FileTreeNode,
  target: string,
  accu: FileTreeNode[]
): false | FileTreeNode[] => {
  if (!treeNode) return [];
  if (target === "0") return [{ key: treeNode.key, title: treeNode.title }];
  if (treeNode.key === target) return [...accu, treeNode];
  if (!treeNode.children) return false;

  const nextPossible: false | FileTreeNode[] = getNodePathList(
    treeNode.children,
    target,
    [...accu, treeNode]
  );

  if (nextPossible) return nextPossible;

  return false;
};

const getNodePathList = (
  treeNodeList: FileTreeNode[],
  target: string,
  accu: FileTreeNode[]
): false | FileTreeNode[] => {
  if (treeNodeList.length === 0) return false;

  const nextPossible = getNodePath(treeNodeList[0], target, accu);

  if (nextPossible) return nextPossible;
  return getNodePathList(treeNodeList.slice(1), target, accu);
};

export default getNodePath;
