import "./index.css";
import { useEffect, useState } from "react";
import { 
  UnorderedListOutlined, 
  DownOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { 
  PageHeader,
  Button,
  Card,
  Select,
  Input,
  Table,
  Menu,
  Dropdown,
  message,
  ConfigProvider,
  Spin,
  Cascader,
  Modal
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import ru_RU from 'antd/es/locale/ru_RU';
import en_GB from 'antd/es/locale/en_GB';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import BaseLayout from "../../components/BaseLayout";
import useLoading from "../../hooks/useLoading";

import skuServices from '../../services/sku';
import spuServices from "../../services/spu";
import AikonCascader from '../ClassifyList/components/AikonCascader'

const { SHOW_CHILD } = Cascader
const { confirm } = Modal;

export default function SkuList() {
  const { t, i18n } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams();
  let page: number = Number(searchParams.get("page")) || 1;
  let pageSize: number = Number(searchParams.get("pageSize")) || 20;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  // 分类select
  const [classifyList, setClassifyList] = useState<any>([])
  const [loadings, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    catID: ''
  })

  // 模型select
  const [modalData, setModalData] = useState<any>([])
  const [modalLoading, setModalLoading] = useState(false)
  const [modalID, setModalID] = useState<number>(0)

  // 规格select
  const [specificationList, setSpecificationList] = useState<any>([])
  const [specLoading, setSpecLoading] = useState(false)
  const [specID, setSpecID] = useState({
    specIDs: ''
  })

  // spu select
  const [spuData, setSpuData] = useState<any>([])
  const [spuDataLoading, setSpuDataLoading] = useState(false)
  const [spuID, setSpuID] = useState<number>(0)

  // 价格展示 select
  const [showPrice, setShowPrice] = useState<number>()

  // 非标
  const [nonstandard, setNonstandard] = useState<number>()

  // 关键词搜索 input
  const [input, setInput] = useState<any>()

  // 搜索
  const [searchLoading, setSearchLoading] = useState(false)

  const columns = [
    {
      key: "skuID",
      title: "sku_id",
      dataIndex: "id",
      width: 150
    },
    {
      key: "sku",
      title: t("skuList.columns.skuCode"),
      dataIndex: "code",
      width: 250
    },
    {
      key: "skuKey",
      title: t("skuList.columns.skuKey"),
      dataIndex: "key",
      width: 400
    },
    {
      key: "isDefault",
      title: t("skuList.columns.isDefault"),
      dataIndex: "isDefault",
      width: 150
    },
    {
      key: "hasCustom",
      title: t("skuList.columns.nonstandard"),
      dataIndex: "nonstandard",
      width: 100,
      align: "center" as const,
      render: (text:any, record:any) =>
        record.hasCustom === 1 ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#bfbfbf" />
        )
    },
    {
      key: "price",
      title: t("skuList.columns.price"),
      dataIndex: "price",
      width: 100,
      sorter: {
        compare: (a:any, b:any) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      key: "showPrice",
      title: t("skuList.columns.showPrice"),
      dataIndex: "showPrice",
      width: 150
    },
    {
      key: "name",
      title: t("skuList.columns.spuName"),
      dataIndex: "spuName",
      width: 200
    },
    {
      key: "spuModel",
      title: t("skuList.columns.model"),
      dataIndex: "model",
      width: 200
    },
    {
      key: "action",
      title: t("skuList.columns.action"),
      dataIndex: "action",
      width: 100,
      fixed: "right" as const,
      render: (_: any, record: any) => {
        const menu = (
          <Menu>
            <Menu.Item key="edit" onClick={() => onHandleEdit()}>{t("skuList.action.edit")}</Menu.Item>
            <Menu.Item key="del" onClick={() => onHandleAdd()}>{t("skuList.action.add")}</Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu}>
            <Button type="text" icon={<UnorderedListOutlined />}>
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      }
    },
  ]

  useEffect(() => {
    onPageChange()
    getOptionData()
    modalList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize])

  // 分页
  const handlePageChange = (page: number, pageSize?: number) => {
    setSearchParams({
      page: String(page),
      pageSize: pageSize ? String(pageSize) : "20",
    });
  }

  // 列表
  const getData = async () => {
    try {
      const result = await skuServices.getList({
        page: page,
        pageSize: pageSize,
      })

      const { response } = result.data

      if (response) {
        const { total, list } = response;
        
        setTotal(total);
        setDataSource(
          list.map((item: any) => ({
            id: item.id,
            erpID: item.erpID,
            sku: item.sku,
            skuID: item.skuID,
            spuID: item.spuID,
            price: item.price,
            key: item.key,
            name: item.name,
            hasCustom: item.hasCustom,
            spuModel: item.spuModel
          }))
        )
      }
      
    } catch (error) {
      message.error("Error")
    }
  }

  // 分类select
  const getOptionData = async () => {
    try {
      setLoading(true)

      const catRes = await spuServices.getCategories()

      const { response: catResponse } = catRes.data

      if(catResponse) {
        let tree = JSON.parse(JSON.stringify(catResponse))
        tree.unshift({id: 0, key: 0, name: "顶级"})        
        setClassifyList(treeParentId(tree))

        setLoading(false)
      }
    } catch (error) {
      message.error("error")
      setLoading(false)
    }
  }

  // 处理最后一级无法选中
  const treeParentId = (tree: any[], pid?: any) => {
    for (let i = 0; i < tree.length; i++) {
      let item = tree[i];
      item["parentId"] = pid;
      if(item.parentId && !item.children) {
        // item["disabled"] = true
      }
      if(item.children && item.children.length !== 0) {
        treeParentId(item.children, item.id)
      }
    }
    return tree;
  }

  const onCascaderChange = (val: any) => {
    setFormData(Object.assign(formData, {catID: val}))
  }

  // 模型列表
  const modalList = async () => {
    try {
      setModalLoading(true)

      const res = await skuServices.getModelList()

      const {response} = res.data
      
      if (response) {
        setModalData(response.map((item: any) => {
          return {
            value: item.id,
            label: item.name
          }
        }))
      }

      setModalLoading(false)
    } catch (error) {
      message.error("error")

      setModalLoading(false)
    }
  }

  // 规格列表
  const getSpecData = async (id: number) => {
    try {
      setSpecLoading(true)

      setModalID(id)

      const res = await skuServices.getSpecList(id)
      
      const {response} = res.data
      
      if (response) {
        let tree = JSON.parse(JSON.stringify(response))

        // id相同特殊处理
        // for (let i = 0; i < tree.length; i++) {
        //   let item = tree[i];
        //   item.id = 'p' + item.id
        // }

        setSpecificationList(tree)

        setSpecLoading(false)
      }
    } catch (error) {
      message.error("error")

      setSpecLoading(false)
    }
  }

//   const onCascaderChanges = (val: any) => {
//     // id相同特殊处理（选中时）
//     console.log('val',val);
    
//     if (typeof val === 'string') {
//       let id = val.replace(/[p]/g, '')
// console.log('id',id);

//       setSpecID(Object.assign(specID, {specID: id}))
//     } else {
//       console.log('val2',val);
      
//       setSpecID(Object.assign(specID, {specID: val}))
//     }
//   }

  // spu select
  const getSpuSelectData = async () => {
    try {
      if (!formData.catID) {
        return message.error(t("skuList.select.select"))
      }

      setSpuDataLoading(true)

      let list = getParentsById(classifyList, Number(formData.catID))
      let IDs = []
      
      for (let i in list) {
        let item = list[i]
        
        if (item.id) {
          IDs.unshift(item.id)
        }
      }

      let param = {
        catIDS: IDs
      }
      
      const res = await skuServices.getSpuSelect(param)

      const {response} = res.data

      if (response) {
        setSpuData(response.map((item: any) => {
          return {
            value: item.id,
            label: item.name
          }
        }))

        setSpuDataLoading(false)
      }
    } catch (error) {
      message.error("error")
      
      setSpuDataLoading(false)
    }
  }

  // 根据id查找父级元素
  const getParentsById = (list:any[], id:number) => {
    for (let i in list) {
      if (list[i].id === id) {
        //查询到就返回该数组对象
        return [list[i]]
      }

      if (list[i].children) {
        let node:any = getParentsById(list[i].children, id)

        if (node !== undefined) {
          //查询到把父节点连起来
          return node.concat(list[i])
        }
      }
    }    
  }
  
  // 搜索功能
  const handleSearch = async () => {
    try {
      setSearchLoading(true)

      let params = {
        selectCate: formData.catID.toString().split(','),
        selectGoods: spuID,
        selModel: modalID,
        selType: specID,
        selstandard: showPrice,
        isCustom: nonstandard,
        key: input
      }

      const res = await skuServices.getList({
        page: page,
        pageSize: pageSize,
        params: params
      })

      const { response } = res.data

      if (response) {
        const { total, list } = response;
        
        setTotal(total);
        setDataSource(
          list.map((item: any) => ({
            id: item.id,
            erpID: item.erpID,
            sku: item.sku,
            skuID: item.skuID,
            spuID: item.spuID,
            price: item.price,
            key: item.key,
            name: item.name,
            hasCustom: item.hasCustom,
            spuModel: item.spuModel
          }))
        )

        setSearchLoading(false)
      }
    } catch (error) {
      message.error("Error")
      
      setSearchLoading(false)
    }
  }
  
  // 重置功能
  const handleReset = async() => {
    window.location.reload()
  }

  let refreshSum: number = 0
  let refreshNum: number = 0

  // 更新功能
  const handleUpdate = async (id?: any) => {
    try {
        let params: any = {}

        if (id) {
          params['skuID'] = id
        }

        const res = await skuServices.updateSku(params)
        console.log(res.data);
        
        const { 
          response 
        } = res.data

        const {
          page,
          sku,
          status
        } = response

        if (!params.skuID) {
          refreshSum = page
        }

        if (sku && status === 2) {
          message.success(t("skuList.prompt.updatePrompt") + (refreshSum - refreshNum) + t("skuList.prompt.close"))

          refreshNum += 1

          handleUpdate(sku)
        } else {
          getData()
        }
    } catch (error) {
      message.error("Error")
    }
  }

  // 更新前提示
  const showConfirm = () => {
    confirm({
      title: t("skuList.prompt.showConfirm"),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleUpdate(undefined)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }



  const [onPageChange, loading] = useLoading(getData);

  const onHandleEdit = () => {

  }

  const handleExcel = () => {
    
  }

  const onHandleAdd = () => {

  }

  const onChange = ( sorter:any ) => {
    console.log('params', sorter);
  }

  let language = i18n.language === 'zh-CN' ? zhCN : i18n.language === 'ru-RU' ? ru_RU : en_GB

  return (
    <BaseLayout title={t("route.skuList")}>
      <PageHeader
        title={t("skuList.title")}
        style={{ padding: "16px 0" }}
        extra={[
          <Button
            type="primary"
            onClick={handleExcel}
            style={{
              "background": "#e6a23c",
              "borderColor": "#e6a23c",
            }}
          >
            {t("skuList.action.excel")}
          </Button>,
          <Button
            type="primary"
            onClick={showConfirm}
          >
            {t("skuList.action.update")}
          </Button>
        ]}
      />

      <Card style={{ marginTop: 24 }}>
        <div className="search-item">
          <div className="search-list">
            <span className="s-l-title">
              分类:
            </span>
            <Spin spinning={loadings}>
              <AikonCascader 
                fieldNames={{ 
                  label: 'name', 
                  value: 'id', 
                  children: 'children' 
                }} 
                width={'200px'}
                data={classifyList} 
                value={formData.catID} 
                radioChange={onCascaderChange}
              />
            </Spin>
            
          </div>
          <div className="search-list">
            <span className="s-l-title">
              spu:
            </span>
            <Select
              placeholder={t("skuList.select.all")}
              style={{
                width: 160,
              }}
              options={spuData}
              onClick={getSpuSelectData}
              loading={spuDataLoading}
              onSelect={(key: number) => setSpuID(key)}
              allowClear={true}
            />
          </div>
          <div className="search-list">
            <span className="s-l-title">
              模型:
            </span>
            <Spin spinning={modalLoading}>
              <Select
                placeholder={t("skuList.select.all")}
                style={{
                  width: 190,
                }}
                options={modalData}
                onSelect={(key:number) => getSpecData(key)}
              />
            </Spin>
          </div>
          <div className="search-list">
            <span className="s-l-title">
              规格:
            </span>
            <Spin spinning={specLoading}>
              <Cascader
                style={{
                  width: 200,
                }}
                options={specificationList}
                multiple
                maxTagCount="responsive"
                showCheckedStrategy={SHOW_CHILD}
                fieldNames={{ 
                  label: 'name', 
                  value: 'id', 
                  children: 'children' 
                }} 
                onChange={(id:any) => setSpecID(id)}
              />
              {/* <AikonCascader 
                fieldNames={{ 
                  label: 'name', 
                  value: 'id', 
                  children: 'children'
                }} 
                width={'200px'}
                data={specificationList} 
                value={specID.specIDs} 
                radioChange={onCascaderChanges}
              /> */}
            </Spin>
          </div>
          <div className="search-list">
            <span className="s-l-title">
              价格展示:
            </span>
            <Select
              placeholder={t("skuList.select.all")}
              style={{
                width: 160,
              }}
              defaultValue=""
              options={[
                {
                  value: '',
                  label: t("skuList.select.all"),
                },
                {
                  value: '1',
                  label: t("skuList.select.yes"),
                },
                {
                  value: '0',
                  label: t("skuList.select.no"),
                }
              ]}
              onSelect={(key:any) => setShowPrice(key)}
            />
          </div>
          <div className="search-list">
            <span className="s-l-title">
              非标:
            </span>
            <Select
              placeholder={t("skuList.select.all")}
              style={{
                width: 160,
              }}
              defaultValue=""
              options={[
                {
                  value: '',
                  label: t("skuList.select.all"),
                },
                {
                  value: '1',
                  label: t("skuList.select.yes"),
                },
                {
                  value: '0',
                  label: t("skuList.select.no"),
                }
              ]}
              onSelect={(key:any) => setNonstandard(key)}
            />
          </div>
          <div className="search-list">
            <Input 
              placeholder={t("skuList.select.search")}
              style={{ width: 200 }}
              value={input}
              onChange={(event:any) => setInput(event.target.value)}
            />
          </div>
          <div className="search-list">
            <Button
              className="s-l-search"
              type="primary"
              onClick={handleSearch}
              loading={searchLoading}
            >
              {t("skuList.action.search")}
            </Button>
            <Button
              className="s-l-reset"
              type="primary"
              onClick={handleReset}
              style={{
                "background": "#67C23A",
                "borderColor": "#67C23A",
              }}
            >
              {t("skuList.action.reset")}
            </Button>
          </div>
        </div>
      </Card>

      <Card style={{ marginTop: 24 }}>
        <ConfigProvider locale={language}>
          <Table
            columns={columns}
            onChange={onChange}
            dataSource={dataSource}
            scroll={{ x: 325 }}
            pagination={{
              current: page,
              pageSize,
              total,
              onChange: handlePageChange,
            }}
            loading={loading || searchLoading}
          />
        </ConfigProvider>
      </Card>
    </BaseLayout>
  )
}