import { useState } from "react";

const useLoading = (fn: any, callback?: any, errorCallback?: any) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const requestAPI = async (...rest: any) => {
    try {
      setLoading(true);
      await fn(...rest);

      callback && callback();
    } catch (e: any) {
      errorCallback && errorCallback();

      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return [requestAPI as any, loading, error];
};

export default useLoading;
