import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import useStorage from "../../stores/storage";

const SelectAll = () => {
  const { t } = useTranslation();
  const { fileList, selected, setSelected } = useStorage();

  const handleSelectAll = () => {
    if (selected.length === fileList.length) {
      setSelected([]);
    } else {
      setSelected(fileList.map((item) => item.id));
    }
  };

  if (fileList.length <= 0) return <></>;

  return (
    <div style={{ padding: "12px 3px", marginBottom: 12 }}>
      <Checkbox
        onClick={handleSelectAll}
        indeterminate={selected.length > 0 && selected.length < fileList.length}
        checked={selected.length === fileList.length}
      >
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {t("fileManager.grid.selectAll")}
        </span>
      </Checkbox>
    </div>
  );
};

export default SelectAll;
