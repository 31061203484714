import React, { useState } from "react";
import {
  Layout,
  Row,
  Col,
  LayoutProps,
  Space,
  Menu,
  Dropdown,
  Modal,
} from "antd";
import Brand from "./Brand";
import "./BaseLayout.css";
import LanguageMenu from "./LanguageMenu";
import useTitle from "../hooks/useTitle";
import UserMenu from "./UserMenu";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import useAuth from "../hooks/useAuth";
// import loginService from "../services/login";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { languages } from "../i18n";
const { Header, Content, Footer } = Layout;

interface BaseLayoutProps extends LayoutProps {
  title?: string;
  childrenClassName?: string;
  children: React.ReactNode;
}

const BaseLayout = ({ title, children, childrenClassName, ...rest }: BaseLayoutProps) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { xs, sm, md } = useBreakpoint();
  const isMobile = xs || (sm && !md);
  useTitle(title);

  // user menu
  const { delAuth, isAdmin } = useAuth();
  const handleSignout = async () => {
    const role = isAdmin ? "admin" : "user";
    // await loginService.signout();
    delAuth();

    if (role === "admin") {
      navigate("/admin");
    } else {
      navigate("/login");
    }
  };

  // language menu
  const [languageDialogVisible, setLanguageDialogVisible] = useState(false);
  const handleMenuClick = ({ key }: { key: string }) => {
    if (key.startsWith("/")) {
      navigate(key);
    } else {
      if (key === "language") {
        setLanguageDialogVisible(true);
      } else if (key === "signout") {
        handleSignout();
      }
    }
  };

  const navMenu = (
    <Menu
      mode="horizontal"
      selectedKeys={[location.pathname]}
      onClick={({ key }) => navigate(key)}
      style={{ minWidth: 500 }}
    >
      <Menu.Item key="/spus">{t("route.spuList")}</Menu.Item>
      {/* {
        isAdmin && (
          <Menu.Item key="/models">{t("route.modelList")}</Menu.Item>
        )
      } */}
      <Menu.Item key="/">{t("route.selection")}</Menu.Item>
      {
        isAdmin && (
          <Menu.Item key="/filemanager">{t("route.fileManager")}</Menu.Item>
        )
      }
      {
        isAdmin && (
          <Menu.SubMenu key="product" title={t("route.baseData")}>
            <Menu.Item key="/models">{t("route.modelList")}</Menu.Item>
            <Menu.Item key="/classifys">{t("route.classifyList")}</Menu.Item>
            <Menu.Item key="/suppliers">{t("route.supplierList")}</Menu.Item>
            <Menu.Item key="/document">{t("route.documentList")}</Menu.Item>
            <Menu.Item key="/sku">{t("route.skuList")}</Menu.Item>
          </Menu.SubMenu>
        )
      }
      
    </Menu>
  );

  const menu = (
    <Menu
      mode="vertical"
      style={{
        width: "100vw",
        top: 20,
        boxShadow: "rgb(0 0 0 / 12%) 0px 10px 20px -4px",
      }}
      onClick={handleMenuClick}
    >
      {/* <Menu.Item className="menuitem-padding" key="/home">
        {t("route.homepage")}
      </Menu.Item> */}
      <Menu.Item className="menuitem-padding" key="/spus">
        {t("route.spuList")}
      </Menu.Item>
      {
        isAdmin && (
          <Menu.Item className="menuitem-padding" key="/models">
            {t("route.modelList")}
          </Menu.Item>
        )
      }
      <Menu.Item className="menuitem-padding" key="/">
        {t("route.selection")}
      </Menu.Item>
      {
        isAdmin && (
          <Menu.Item className="menuitem-padding" key="/filemanager">
            {t("route.fileManager")}
          </Menu.Item>
        )
      }
      <Menu.Divider />
      <Menu.Item className="menuitem-padding" key="language">
        {t("chart.language")}
      </Menu.Item>
      <Menu.Item className="menuitem-padding" key="signout">
        {t("layout.signout")}
      </Menu.Item>
    </Menu>
  );

  const childrenClazz = `bl-padding ${childrenClassName}`;

  return (
    <Layout {...rest} className="layout" style={{ minHeight: "100vh" }}>
      <Header className="bl-padding bl-header">
        <Row justify="space-between" align="middle" wrap={false}>
          <Col style={{ height: 64 }}>
            <Space
              align="start"
              direction="horizontal"
              style={{ justifyContent: "start" }}
              wrap={false}
            >
              <Brand align="left" />
              {!isMobile && navMenu}
            </Space>
          </Col>
          <Col style={{ height: 64 }}>
            <Space
              align="end"
              style={{ justifyContent: "end" }}
              direction="horizontal"
              size="large"
              wrap={false}
            >
              {!isMobile && <LanguageMenu />}
              {!isMobile && <UserMenu />}
              {isMobile && (
                <Dropdown overlay={menu}>
                  <span style={{ cursor: "pointer" }}>
                    <MenuOutlined />
                  </span>
                </Dropdown>
              )}
            </Space>
          </Col>
        </Row>
      </Header>
      <Content className={childrenClazz}>
        {children}
      </Content>
      <Footer style={{ height: "5vh" }} />
      <Modal
        title={t("layout.changeLang")}
        visible={languageDialogVisible}
        onCancel={() => setLanguageDialogVisible(false)}
        footer={null}
        centered
      >
        <Menu selectedKeys={[i18n.language]} style={{ border: "none" }}>
          {languages.map((lang) => (
            <Menu.Item
              key={lang.key}
              onClick={() => {
                setLanguageDialogVisible(false);
                i18n.changeLanguage(lang.key);
                window.location.reload();
              }}
            >
              {lang.name}
            </Menu.Item>
          ))}
        </Menu>
      </Modal>
    </Layout>
  );
};

export default BaseLayout;
