import ChartLayout from "../../../components/ChartLayout";
import PerformanceSpeedChart from "./PerformanceSpeedChart";
import PerformanceSpeedForm from "./PerformanceSpeedForm";
import PerformanceResult from "../Performance/PerformanceResult";
import useSpuPoints from "../../../stores/spuPoints";
import EmptyChart from "../components/EmptyChart";
import Loading from "../components/Loading";

const PerformanceSpeed = () => {
  const { name, info, dataset, performanceSpeed, loading } = useSpuPoints();
  const { fieldsValue, fieldsResult, speedItems } = performanceSpeed;

  if (loading) return <Loading />;

  if (!name || !dataset) return <EmptyChart />;

  return (
    <ChartLayout
      chart={
        <PerformanceSpeedChart
          key={name}
          name={name}
          info={info}
          dataset={dataset}
          performance={fieldsValue}
          multiSpeed={speedItems}
        />
      }
      form={<PerformanceSpeedForm />}
      result={<PerformanceResult fieldsResult={fieldsResult} />}
    />
  );
};

export default PerformanceSpeed;
