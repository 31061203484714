import { TextData } from "../types";
import { formatTextLine } from "../utils";
import { RenderItem } from "../components/Chart";

/**
 * 
 * @param str label 文字
 * @param offset label 偏移量
 * @param index label 需要显示的点 [Q-EFF 会有负的导致不渲染, 需要手动操作]
 */
export const renderLabel = (str?: string, offset: number[] = [30, -5], index?: any) => {
  if (str) {
    return {
      show: true,
      formatter: (data: any) => {
        const {
          dataIndex
        } = data;

        if (dataIndex === 0 || dataIndex === index) {
          return str;
        }

        return "";
      },
      offset,
    };
  }

  return {
    show: false
  };
};

// 自定义绘制type
export const renderTypeLabel =
  (x: number, y: number, text: string) => {
    return () => {
      return {
        type: "text",
        style: {
          text,
          lineHeight: 16,
          x,
          y,
        },
        z2: 200,
      }
    };
  }

export const genRenderItem = (
  type: any,
  x?: number,
  y?: number,
  data?: TextData[]
): RenderItem => {
  let text = "";

  // 仅绘制type
  if (!x || !y) {
    return () => {
      return {
        type
      };
    };
  }

  if (data) {
    data.forEach(d => {
      if (d.value) {
        text += formatTextLine(d.label, d.value, d.unit) + "\n";
      }
    });
  }

  // 绘制文本
  return (_param, api) => {
    if (type === "text") {
      return {
        type,
        style: {
          text,
          lineHeight: 16,
          x,
          y,
        },
        z2: 200
      };
    }

    const [cx, cy] = api.coord([x, y]); // 将数据值映射到坐标系上

    if (type === 'circle1') {
      return {
        type: 'circle',
        shape: { cx, cy, r: 5 },
        style: { fill: "#1890ff" },
        z2: 200
      }
    }

    if (type === 'circle2') {
      return {
        type: 'circle',
        shape: { cx, cy, r: 3.5 },
        style: { fill: "#ffffff" },
        z2: 201
      }
    }

    // 绘制交点
    return {
      type: 'circle',
      shape: { cx, cy, r: 2 },
      style: { fill: "#1890ff" },
      z2: 202
    }
  };
};

export const exportTemplate = {
  type: "png",
  pixelRatio: 2,
  backgroundColor: "#fff",
  excludeComponents: ["toolbox"],
};
