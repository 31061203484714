import create from "zustand";
import getNodePath, { FileTreeNode } from "../libs/nodePath";
import storageServices from "../services/storage";

interface FileState {
  id: number;
  erpId: number;
  name: string;
  path: string;
  type: "dir" | "file";
  createdAt: string;
}

export type FileListState = FileState[];

interface StorageBaseState {
  view: "list" | "grid";
  loading: boolean;
  loadingStructure: boolean;
  fileList: FileListState;
  pathList: FileTreeNode[];
  currentRef: number;
  selected: number[];
  history: FileTreeNode[];
  searchFlag: boolean;
}

interface StorageState extends StorageBaseState {
  resetAll: () => void;
  setView: (view: "list" | "grid") => void;
  setLoading: (loading: boolean) => void;
  setLoadingStructure: (loading: boolean) => void;
  setCurrentRef: (currentRef: number) => void;
  setSelected: (selected: number[]) => void;
  toggleSelected: (id: number) => void;
  updateHistory: (target: string, treeNode: FileTreeNode) => void;
  fetchFolderTree: () => void;
  fetchCurrentDirectory: () => void;
  createDirectory: (name: string) => void;
  deleteObjects: (ids: number[]) => void;
  updateName: (id: number, name: string) => void;
  search: (keyword: string) => void;
  setSearchFlag: (flag: boolean) => void;
}

const parseFileList = (data: any[]): FileListState => {
  if (!data) return [];
  if (data.length <= 0) return [];

  return data.map((item: any) => ({
    id: item.id,
    erpId: item.erp_id,
    name: item.name,
    path: item.path,
    type: item.type === 0 ? "file" : "dir",
    createdAt: item.create_time,
  }));
};

const parseFolderTree = (data: any): FileTreeNode => {
  const isNode = data.children;

  if (isNode) {
    return {
      key: String(data.id),
      title: data.name,
      children: data.children.map(parseFolderTree),
    };
  }
  return {
    key: String(data.id),
    title: data.name,
    isLeaf: true,
  };
};

const initialValues: StorageBaseState = {
  view: "list",
  loading: false,
  loadingStructure: false,
  fileList: [],
  pathList: [],
  currentRef: 0,
  selected: [],
  history: [],
  searchFlag: false
};

const useStorage = create<StorageState>((set, get) => ({
  ...initialValues,
  resetAll: () => set({ ...initialValues }),
  setView: (view: "list" | "grid") => set(() => ({ view })),
  setLoading: (loading: boolean) => set({ loading }),
  setLoadingStructure: (loading: boolean) => set({ loadingStructure: loading }),
  setCurrentRef: (currentRef: number) => set(() => ({ currentRef })),
  setSelected: (selected: number[]) => set(() => ({ selected })),
  toggleSelected: (id: number) =>
    set((state) => {
      if (state.selected.includes(id)) {
        return { selected: state.selected.filter((item) => item !== id) };
      }
      return { selected: [...state.selected, id] };
    }),
  updateHistory: (target: string, treeNode: FileTreeNode) => {
    const history = getNodePath(treeNode, target, []);
    if (history) set(() => ({ history }));
  },
  fetchFolderTree: async () => {
    set({ loadingStructure: true });

    const res = await storageServices.getFolderTree();
    const rootId = res.data.response.id;
    const pathList = parseFolderTree(res.data.response) as FileTreeNode;

    if (get().currentRef) {
      set({ pathList: [pathList], loadingStructure: false });
    } else {
      set({
        pathList: [pathList],
        loadingStructure: false,
        currentRef: rootId,
      });
    }
  },
  fetchCurrentDirectory: async () => {
    set({ loading: true });

    const ref = get().currentRef;
    let res;
    if (ref) {
      res = await storageServices.getCurrentDirectory(ref);
    } else {
      res = await storageServices.getCurrentDirectory();
    }
    const fileList = parseFileList(res.data.response);

    set({ fileList, selected: [], loading: false });
  },
  createDirectory: async (name: string) => {
    await storageServices.createDirectory(name, get().currentRef);
  },
  deleteObjects: async (ids: number[]) => {
    await storageServices.deleteObjects(ids);
  },
  updateName: async (id: number, name: string) => {
    await storageServices.updateName(id, name);
  },
  search: async (keyword: string) => {
    set({ loading: true });
    const res = await storageServices.search(keyword);
    set({
      fileList: parseFileList(res.data.response),
      loading: false,
      // selected: [],
      // currentRef: 0,
    });
  },
  setSearchFlag: (flag: boolean) => {
    set({
      searchFlag: flag
    });
  }
}));

export default useStorage;
