import './AikonCascader.css'
import {
  Dropdown,
  Input,
  Radio
} from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";

export default function AikonCascader(props: any) {
  const { t } = useTranslation()
  const { data, value, radioChange, fieldNames, width } = props
  const [dataSource, setDataSource] = useState<any>([{list: data}])
  const [cascaderKey] = useState(new Date().getTime())
  const [label, setLabel] = useState('')
  const [radio, setRadio] = useState('')
  const [radioIds, setRadioIds] = useState<any>([])
  const [fieldItem, setFieldItem] = useState({value: 'key', label: 'label', children: 'children'})
  const [widths, setWidths] = useState<string>('400px')

  useEffect(() => {
    onDropOpen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, data])

  // 初始化以及，展开的时候调用
  const onDropOpen = () => {
    let newList = [{list: data}]
    
    if((value && value.toString()) || value === 0) {
      let list = searchParent(data, value, fieldItem.value)
      let ids = []
      let names = []
      if(list && list.length) {
        for(let i=0; i<list.length; i++) {
          let item = list[i]
          ids.push(item[fieldItem.value])
          names.push(item[fieldItem.label])
          if(i !== 0) {
            let item2 = list[i - 1]
            if(item2 && item2[fieldItem.children] && item2[fieldItem.children].length) {
              newList.push({list: item2[fieldItem.children]})
            }
          }
        }
      }
      setLabel(names.join(' / '))
      setRadio(value)
      setRadioIds(ids)
    }

    if(fieldNames) {
      setFieldItem(Object.assign(fieldItem, fieldNames))
    }

    if (width) {
      setWidths(width)
    }

    setDataSource(newList)
  }

  // 展开下级选项
  const onItemClick = (item: any, index: number) => {
    let list = dataSource
    if(item[fieldItem.children] && item[fieldItem.children].length) {
      list = [...list.slice(0, index + 1), {list: item[fieldItem.children]}]
    } else {
      list = index > 0?list.slice(0, index + 1):list.slice(0, 1)
    }
    setDataSource(list)
  }

  // 选中
  const onRadioChange = (e: any) => {
    let ids = []
    let names = []
    let list = searchParent(data, e.target.value, fieldItem.value)
    for(let i=0; i<list.length; i++) {
      let item = list[i]
      ids.push(item[fieldItem.value])
      names.push(item[fieldItem.label])
    }
    setRadio(e.target.value)
    setLabel(names.join(' / '))
    radioChange && radioChange(e.target.value)
    setRadioIds(ids)
  }

  // 查找父元素
  const searchParent = (tree: any[], key: number, field:string='key') => {
    let list = []
    for (let i = 0; i < tree.length; i++) {
        const item = tree[i]
        if (item[field] === key) {
            //若查询到对应的节点，则直接返回
            list.push(item)
            break
        } else if (item[fieldItem.children] && item[fieldItem.children].length !== 0) {
            //判断是否还有子节点，若有对子节点进行循环调用
            let parent: any = searchParent(item[fieldItem.children], key, field)
            //若parent的长度不为0，则说明子节点在该节点的children内，记录此节点，并终止循环
            if (parent.length !== 0) {
                parent.unshift(item)
                list = parent
                break
            }
        }
    }
    return list;
  }

  const CascaderList = () => {
    return (
      <Radio.Group value={radio} onChange={onRadioChange}>
        <div className='aikon-cascader' key={cascaderKey}>
          {
            dataSource.map((item: any, index: number) => (
              item.list && item.list.length?(
                <div key={index}>
                  {
                    item.list.map((item2: any) => (
                      <div 
                        className={`aikon-cascader-item ${radioIds.indexOf(item2.key) !== -1?'selected-cls':''}`} 
                        key={item2[fieldItem.value]} 
                        onClick={() => onItemClick(item2, index)}>
                        <Radio value={item2[fieldItem.value]}  key={item2[fieldItem.value]}></Radio>
                        <span>{item2[fieldItem.label]}</span>
                        {item2[fieldItem.children] && item2[fieldItem.children].length?<RightOutlined className='aikon-cascader-item-arrow' />:null}
                      </div>
                    ))
                  }
              </div>
              ): null
            ))
          }
          
        </div>
      </Radio.Group>
    )
  }

  return (
    <Dropdown dropdownRender={() => CascaderList()} trigger={['click']} onOpenChange={onDropOpen}>
      <Input placeholder={t("skuList.title")} readOnly style={{width: widths}} value={label} />
    </Dropdown>
  )
}