import React, { useState } from "react";

const useField = (initialValue?: any) => {
  const [value, setValue] = useState(initialValue || "");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return { value, onChange, setValue };
};

export default useField;
