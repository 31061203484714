import { useEffect, useState } from "react";

const useChartImage = () => {
  const chartNames = ["performanceChart", "speedChart", "seriesParallelChart"];
  const [images, setImages] = useState<any>(null);
  const reducer = (accu: any, curr: string) => {
    const image = sessionStorage.getItem(curr);
    if (image) {
      return { ...accu, [curr]: image };
    }
    return accu;
  };

  useEffect(() => {
    const newImages = chartNames.reduce(reducer, {});
    setImages(newImages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addImage = (name: string, image: string) => {
    // 如果图表名不合法，则返回空
    if (!chartNames.includes(name)) return;
    // 如果图表没有存储过，则添加图表名
    if (!sessionStorage.getItem(name)) {
      const newImages = { ...images, [name]: image };
      setImages(newImages);
    }
    // 创建或覆盖图表数据
    sessionStorage.setItem(name, image);
  };

  const delImage = (name: string) => {
    if (!chartNames.includes(name)) return;

    const newImages = { ...images };
    delete newImages[name];
    sessionStorage.removeItem(name);
    setImages(newImages);
  };

  const delAllImages = () => {
    sessionStorage.clear();
    setImages([]);
  };

  const getAllImages = () => {
    const reducer = (accu: any, curr: string) => {
      const image = sessionStorage.getItem(curr);
      if (image) {
        return [...accu, image];
      }
      return accu;
    };

    return chartNames.reduce(reducer, []);
  };

  const exportTemplate = {
    type: "png",
    pixelRatio: 2,
    backgroundColor: "#fff",
    excludeComponents: ["toolbox"],
  };

  return {
    images,
    getAllImages,
    addImage,
    delImage,
    delAllImages,
    exportTemplate,
  };
};

export default useChartImage;
