import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core";

import {
  CustomChart,
  LineChart,
  LineSeriesOption,
  CustomSeriesOption,
} from "echarts/charts";

import {
  GridComponent,
  GridComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  LineChart,
  CustomChart,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  CanvasRenderer,
]);

export type EChartsOption = echarts.ComposeOption<
  | LineSeriesOption
  | GridComponentOption
  | CustomSeriesOption
  | TooltipComponentOption
  | ToolboxComponentOption
>;

export type RenderItem = CustomSeriesOption["renderItem"];

interface ChartProp {
  option: EChartsOption;
  notMerge?: boolean;
  style?: Object;
  className?: string;
  onChange?: (instance: echarts.EChartsType) => void;
  handleChartClick?: any
}

const Chart = ({
  option,
  notMerge = false,
  style,
  className,
  onChange,
  handleChartClick
}: ChartProp) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartInstance, setChartInstance] = useState<echarts.EChartsType>();

  useEffect(() => {
    if (chartRef.current) {
      setChartInstance(echarts.init(chartRef.current));
    }

    return () => {
      chartInstance?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartInstance && option) {
      chartInstance?.setOption(option, notMerge);

      if (onChange && !chartInstance.isDisposed()) {
        onChange(chartInstance);
      }

      if (handleChartClick) {
        const onClick = (params: any) => {
          handleChartClick && handleChartClick(params, chartInstance);
        }

        chartInstance?.getZr().off('click');
        chartInstance?.getZr().on('click', onClick);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartInstance, option, notMerge]);

  return (
    <div
      ref={chartRef}
      className={className}
      style={style}
    />
  );
};

export default Chart;
