import React, { useEffect, useState } from "react";
import { Form } from "antd";
import Chart, { EChartsOption } from "../../../components/Chart";
import { LineSeriesOption } from "echarts/charts";
import PerformanceForm from "./PerformanceForm";
import { DataSource } from "../../../types";

import {
  calcYAxis,
  findIntersectionPoint,
  createQP,
  createQH2Data,
  createQEFF2Data,
  createQNPSH2Data,
  powerFormula,
  precision,
  createK,
  createAuxiliaryData,
  createDiameterData,
  createSpeedData,
  createFrequencyData,
  convertEFF1ToEFF2,
} from "../../../utils";

import { renderLabel, genRenderItem } from "../../../libs/echar";
import { DataPoint } from "regression";
import { useTranslation } from "react-i18next";
import ChartLayout from "../../../components/ChartLayout";
import { useSearchParams } from "react-router-dom";
import useChartImage from "../../../hooks/useChartImage";
import { EChartsType } from "echarts/core";

// may be 多的 性能图表

const parseNumberParam = (input: string | null, isfloat: boolean = true) => {
  if (input === null) return undefined;
  const result = isfloat ? Number(input) : parseInt(input);
  if (!result) return undefined;

  return result;
};

const PerformanceChart = ({ dataSource }: { dataSource: DataSource }) => {
  const defaultSeries: LineSeriesOption[] = [
    {
      name: "QH1",
      type: "line",
      label: renderLabel("Q-H"),
      smooth: true,
      symbolSize: 0,
      lineStyle: {
        width: 1,
      },
      data: dataSource.QH.dummyPoints,
      color: "black",
      animation: false,
    },
    {
      name: "QEFF",
      type: "line",
      label: renderLabel("Q-EFF"),
      smooth: true,
      symbolSize: 0,
      lineStyle: {
        width: 1,
      },
      data: dataSource.QEFF.dummyPoints,
      color: "black",
      xAxisIndex: 0,
      yAxisIndex: 1,
      animation: false,
    },
    {
      name: "QP1",
      type: "line",
      label: renderLabel("Q-P"),
      smooth: true,
      symbolSize: 0,
      lineStyle: {
        width: 1,
      },
      data: dataSource.QP,
      color: "black",
      xAxisIndex: 1,
      yAxisIndex: 2,
      animation: false,
    },
    {
      name: "QNPSH",
      type: "line",
      label: renderLabel("Q-NPSHr"),
      smooth: true,
      symbolSize: 0,
      lineStyle: {
        width: 1,
      },
      data: dataSource.QNPSH ? dataSource.QNPSH.dummyPoints : [],
      color: "black",
      xAxisIndex: 1,
      yAxisIndex: 3,
      animation: false,
    },
  ];

  const initOption: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      feature: {
        saveAsImage: { name: dataSource.name, pixelRatio: 2 },
      },
    },
    grid: [{ bottom: "40%" }, { top: "70%" }],
    xAxis: [
      // Q1
      { name: "m³/h", type: "value" },
      // Q2
      { name: "m³/h", type: "value", gridIndex: 1 },
    ],
    yAxis: [
      // H
      {
        name: "H(m)",
        type: "value",
        min: 0,
      },
      // EFF
      {
        name: "EFF(%)",
        type: "value",
        min: 0,
        max: 200,
        splitNumber: 10,
        axisLabel: {
          formatter: (v: any) => {
            if (v <= 100) {
              return v;
            }

            return '';
          }
        }
      },
      // P
      {
        name: "P(kW)",
        type: "value",
        min: 0,
        gridIndex: 1,
      },
      // NPSHr
      {
        name: "NPSHr(m)",
        type: "value",
        gridIndex: 1,
        min: 0,
        max: 15,
        splitNumber: 5,
      },
    ],
    series: defaultSeries,
  };

  const [option, setOption] = useState(initOption);
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  const quantity = parseNumberParam(searchParams.get("q"));
  const head = parseNumberParam(searchParams.get("h"));
  const type = parseNumberParam(searchParams.get("type"), false) || 0;
  const sg = parseNumberParam(searchParams.get("sg")) || 1;

  useEffect(() => {
    draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, quantity, head, type, sg, i18n.language]);

  const handleSubmit = (values: any): void => {
    const { quantity, head, type, sg } = values;
    if (quantity && head)
      setSearchParams({ q: quantity, h: head, type, sg: sg || 1 });
  };

  const { addImage, delImage, exportTemplate } = useChartImage();

  const handleReset = (): void => {
    form.resetFields();
    delImage("performanceChart");
    setSearchParams({});
  };

  const handleChartChange = (instance: EChartsType) => {
    const result = instance.getDataURL(exportTemplate as any);
    // 当 excludeComponents 执行后，会导致图表上对应的组件暂时消失。
    // 这里强制执行一次，重新让组件显示出来.
    instance.getDataURL();
    addImage("performanceChart", result);
  };

  const draw = (): void => {
    // if don't have data, return (this never happend before)
    if (!dataSource) return;
    // get the interval of P and H
    const [hMax, hInterval] = calcYAxis(
      dataSource.QH.dummyPoints.map((p) => p[1]),
      10,
      1
    );
    const [pMax, pInterval] = calcYAxis(
      dataSource.QP.map((p) => p[1]),
      5,
      1
    );
    // create the origin chart
    let option: EChartsOption = {
      ...initOption,
      yAxis: [
        // H
        {
          name: "H(m)",
          type: "value",
          min: 0,
          max: hMax,
          interval: hInterval,
        },
        // EFF
        {
          name: "EFF(%)",
          type: "value",
          min: 0,
          max: 200,
          splitNumber: 10,
          axisLabel: {
            formatter: (v: any) => {
              if (v <= 100) {
                return v;
              }

              return '';
            }
          }
        },
        // P
        {
          name: "P(kW)",
          type: "value",
          min: 0,
          max: pMax,
          interval: pInterval,
          gridIndex: 1,
        },
        // NPSH
        {
          name: "NPSHr(m)",
          type: "value",
          gridIndex: 1,
          min: 0,
          max: 15,
          splitNumber: 5,
        },
      ],
    };
    // if it doesn't have params or params are invalid, return
    if (quantity === undefined || head === undefined) {
      setOption(option);
      form.resetFields();
      return;
    }
    // if it has params and params are valid, draw other lines
    //  - calculate the intersection point
    const center = (dataSource.range.start + dataSource.range.end) / 2;
    const intersectionPoint = findIntersectionPoint(
      quantity,
      head,
      dataSource.QH.equation,
      type,
      center
    );
    if (!intersectionPoint) return;
    //  - create lines
    // 生成辅助线数据
    const auxData = createAuxiliaryData(
      quantity,
      head,
      0,
      intersectionPoint[0],
      type
    );

    // 根据辅助线生成新的 Q-H 线
    const k: number = createK(quantity, intersectionPoint[0], type);
    const qH2Data = createQH2Data(k, dataSource.QH.dummyPoints, type);

    // 平移 Q-EFF
    let qEFF2Data = createQEFF2Data(k, dataSource.QEFF.dummyPoints, type);

    // 平移 Q-NPSH
    let qNPSH2Data;
    if (dataSource.QNPSH)
      qNPSH2Data = createQNPSH2Data(k, dataSource.QNPSH.dummyPoints, type);

    // 平移 Q-P
    const qh2Points = createQH2Data(
      k,
      dataSource.QH.points as DataPoint[],
      type
    );
    const qEFF2Points = createQEFF2Data(
      k,
      dataSource.QEFF.points as DataPoint[],
      type
    );
    const qP2Data = createQP(qh2Points, qEFF2Points, sg);

    // 叶轮
    const { diameter, diameterUnit } = createDiameterData(
      k,
      dataSource.diameter,
      type
    );

    // 转速
    const speed = createSpeedData(k, dataSource.speed, type);

    // 频率
    const frequency = createFrequencyData(k, dataSource.frequency, type);

    // EFF
    let eff = convertEFF1ToEFF2(dataSource.QEFF, intersectionPoint[0], k);

    // P
    let power = powerFormula([quantity, head], eff / 100) * sg;

    // NPSH
    let npsh: number | null = null;
    if (dataSource.QNPSH) {
      npsh = dataSource.QNPSH.predict(quantity)[1];
    }

    //  - set form fields
    form.setFieldsValue({
      quantity,
      head,
      sg,
      type,
      diameter,
      speed,
      frequency,
      power,
      npsh,
      eff,
      diameterUnit,
    });

    //  - set option
    const units = {
      Q: "m³/h",
      H: "m",
      P: "KW",
      NPSH: "m",
    };

    setOption({
      ...option,
      series: [
        ...defaultSeries,
        {
          name: "aux",
          type: "line",
          smooth: true,
          symbolSize: 0,
          lineStyle: { width: 1 },
          color: "#aaa",
          data: auxData,
          animation: false,
        },
        {
          name: "QH2",
          type: "line",
          smooth: true,
          symbolSize: 0,
          color: "#aaa",
          label: renderLabel(
            type === 0
              ? `${precision(speed, 0)} rpm`
              : `${precision(diameter, 0)} ${diameterUnit}`,
            [50, 35]
          ),
          data: qH2Data,
          animation: false,
        },
        {
          name: "QEFF2",
          type: "line",
          smooth: true,
          symbolSize: 0,
          lineStyle: { width: 1 },
          xAxisIndex: 0,
          yAxisIndex: 1,
          color: "#aaa",
          data: qEFF2Data,
          animation: false,
        },
        {
          name: "QNPSH2",
          type: "line",
          smooth: true,
          symbolSize: 0,
          lineStyle: { width: 1 },
          xAxisIndex: 1,
          yAxisIndex: 3,
          color: "#aaa",
          data: qNPSH2Data,
          animation: false,
        },
        {
          name: "QP2",
          type: "line",
          smooth: true,
          symbolSize: 0,
          lineStyle: { width: 1 },
          xAxisIndex: 1,
          yAxisIndex: 2,
          color: "#aaa",
          data: qP2Data,
          animation: false,
        },
        {
          name: "intersection point",
          type: "custom",
          renderItem: genRenderItem("circle", quantity, head),
          data: [
            {
              name: "intersection point",
              xAxis: quantity,
              yAxis: head,
            },
          ],
          z: 100,
        },
        {
          name: "top info",
          type: "custom",
          renderItem: genRenderItem("text", 330, 65, [
            { label: "Q", value: precision(quantity, 1), unit: units.Q },
            { label: "H", value: precision(head, 1), unit: units.H },
            {
              label: t("chart.diameter"),
              value: precision(diameter, 0),
              unit: diameterUnit,
            },
            {
              label: t("chart.speed"),
              value: precision(speed, 0),
              unit: "rpm",
            },
            {
              label: t("chart.efficiency"),
              value: precision(eff, 2),
              unit: "%",
            },
            {
              label: t("chart.sg"),
              value: precision(sg, 2),
            },
          ]),
          data: [{ x: 0, y: 0 }],
          z: 100,
        },
        {
          name: "bottom info",
          type: "custom",
          renderItem: genRenderItem("text", 365, 495, [
            { label: "P", value: precision(power, 2), unit: units.P },
            {
              label: "NPSHr",
              value: npsh ? precision(npsh, 2) : npsh,
              unit: units.NPSH,
            },
          ]),
          data: [{ x: 0, y: 0 }],
          z: 100,
        },
      ],
    });
  };

  return (
    <ChartLayout
      chart={
        <Chart
          option={option}
          notMerge
          style={{
            width: "500px",
            height: "700px",
            margin: "auto",
          }}
          onChange={handleChartChange}
        />
      }
      form={
        <PerformanceForm
          onFinish={handleSubmit}
          form={form}
          onReset={handleReset}
          type={dataSource.type}
        />
      }
    />
  );
};

export default PerformanceChart;
