import {
  Card,
  Row,
  Col,
  Form,
  Image,
  Space,
  Select,
  Button,
  Descriptions,
  Divider,
} from "antd";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useSpuInfo, { initialSelected } from "../../../stores/spuInfo";

import { SpuAttributeData } from "../../../types";

import Introduction from "./Introduction";
import SelectSpec from "./SelectSpec";

import Loading from "../components/Loading";

// import ImageAttribute from "../components/ImageAttribute";

const { useForm } = Form;
const { Option } = Select;

const SelectLabel = ({ id }: { id: string }) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { info, label, labelLoading, setSelected, resetSelected, clearAttributes, clearSize } = useSpuInfo();

  const handleReset = () => {
    form.resetFields();
    resetSelected();
  };

  const handleSubmit = (values: any) => {
    setSelected(id, values);
  };

  const handleAttrSelect = (val: any) => {
    if (val === 'default') { // 选择默认的
      clearAttributes();
    }
  }

  const handleSizeSelect = (val: any) => {
    if (val === 'default') { // 选择默认的
      clearSize();
    }
  }

  return (
    <Card
      title={
        <div className='spu-info-card-title'>
          <div
            className='sict-title'
            style={{
              display: 'inline-block',
              marginRight: '30px'
            }}
          >
            { t("spuInfo.label.title") }
          </div>
          {
            id ? (
              <div
                className='sict-label'
                style={{
                  display: 'inline-block',
                  fontWeight: 400,
                  fontSize: '13px'
                }}
              >
                <span>{ t("spuInfo.label.id") }:</span>
                <span
                  style={{
                    display: 'inline-block',
                    marginLeft: '6px'
                  }}
                >
                  {id}
                </span>
              </div>
            ) : null
          }
          {
            info.cat_id ? (
              <div
                className='sict-label'
                style={{
                  display: 'inline-block',
                  marginLeft: '20px',
                  fontWeight: 400,
                  fontSize: '13px'
                }}
              >
                <span>{ t("spuInfo.label.catId") }:</span>
                <span
                  style={{
                    display: 'inline-block',
                    marginLeft: '6px'
                  }}
                >
                  {info.cat_id}
                </span>
              </div>
            ) : null
          }
          {
            info.cate_name ? (
              <div
                className='sict-label'
                style={{
                  display: 'inline-block',
                  marginLeft: '20px',
                  fontWeight: 400,
                  fontSize: '13px'
                }}
              >
                <span>{ t("spuInfo.label.class") }:</span>
                <span
                  style={{
                    display: 'inline-block',
                    marginLeft: '6px'
                  }}
                >
                  {info.cate_name}
                </span>
              </div>
            ) : null
          }
        </div>
      }
      style={{ marginBottom: 24 }}
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleSubmit}
        onReset={handleReset}
        initialValues={initialSelected}
      >
        <div className='spu-info-card-content'>
          <Row justify="space-between">
            <Col>
              <Space>
                <Form.Item
                  label={t("spuInfo.label.size")}
                  name="size"
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    style={{ minWidth: 150 }}
                    onChange={handleSizeSelect}
                  >
                    {label.size.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t("spuInfo.label.attribute")}
                  name="attribute"
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    style={{ minWidth: 150 }}
                    onChange={handleAttrSelect}
                  >
                    {label.attribute.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Space>
                  <Button type="default" htmlType="reset">
                    {t("spuInfo.label.reset")}
                  </Button>
                  <Button type="primary" htmlType="submit" loading={labelLoading}>
                    {t("spuInfo.label.submit")}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Card>
  );
};

const CategoryDescription = () => {
  const { t } = useTranslation();
  const { category } = useSpuInfo();
  const imageSrc = category.image ? `${category.image}` : "";

  return (
    <Card title={t("spuInfo.categoryDesc")} style={{ marginBottom: 24 }}>
      <Row justify="center" gutter={16}>
        <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={6}>
          <div style={{ width: "300px", margin: "0 auto" }}>
            <Image src={imageSrc} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={18}>
          <div
            dangerouslySetInnerHTML={{ __html: category.content }}
            style={{ maxWidth: "1000px" }}
          ></div>
        </Col>
      </Row>
    </Card>
  );
};

interface PropertiesProps {
  title: React.ReactNode;
  style?: React.CSSProperties;
  attributes: SpuAttributeData[];
}

const Properties = ({ title, style, attributes }: PropertiesProps) => {
  if (attributes.length <= 0) {
    return null;
  }

  return (
    <Descriptions title={title} size="middle" style={style} bordered>
      {attributes.map((attr) => (
        <Descriptions.Item key={attr.name} label={attr.name}>
          {attr.item}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

const ProductProperties = () => {
  const { t } = useTranslation();
  const { selected, attributes, otherAttributes, specifications, sku } =
    useSpuInfo();

  const notDefault = selected.attribute !== "default";
  const title = notDefault && "default";

  // console.log(notDefault);

  return (
    <Card title={t("spuInfo.productProp")} style={{ marginBottom: 24 }}>
      <Properties title={title} attributes={attributes} />
      {notDefault && (
        <Properties
          title={selected.attribute}
          style={{ marginTop: 24 }}
          attributes={otherAttributes}
        />
      )}
      {attributes.length > 0 && <Divider />}
      <SelectSpec specifications={specifications} sku={sku} />
    </Card>
  );
};

/*
const ProductDetails = () => {
  const { t } = useTranslation();
  const { details } = useSpuInfo();

  if (!details.image) {
    return null;
  }

  return (
    <Card title={t("spuInfo.productDetail")} style={{ marginBottom: 24 }}>
      <>
        <h4>{t("spuInfo.curve")}</h4>
        <ImageAttribute
          attribute={details as SpuImageAttributeData}
          style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
        />
      </>
    </Card>
  );
};
*/

const Info = () => {
  const { id } = useParams();
  const { loading } = useSpuInfo();

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SelectLabel id={String(id)} />
      <CategoryDescription />
      <ProductProperties />
      {/* <ProductDetails /> */}
      <Introduction />
    </>
  );
};

export default Info;
