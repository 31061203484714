import axios from "../libs/axios";

interface SkuListParams {
  page?: number;
  pageSize?: number;
  params?:{};
}

// 获取sku列表
const getList = async (params: SkuListParams) => await axios.post("/skus", params);

// 获取模型列表
const getModelList = async () => await axios.get("/modelsList")

// 查询规格数据
const getSpecList = async (modelID: number) => await axios.get(`/spus/specList?modelID=${modelID}`)

// 获取spu-select数据
const getSpuSelect = async (catIDs: any) => await axios.post("categories/spuList", catIDs)

// 更新
const updateSku = async (skuID?: any) => await axios.post("skus/refresh", skuID)


const skuServices = {
  getList,
  getModelList,
  getSpecList,
  getSpuSelect,
  updateSku
};

export default skuServices;