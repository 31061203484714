import { Button, Form, InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import useSpuPoints from "../../../stores/spuPoints";

const { useForm } = Form;

const MultiSpeedForm = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { setMultiSpeed, resetMultiSpeed } = useSpuPoints();

  const handleSubmit = (values: any) => {
    const { speedItems } = values;

    if (!speedItems) return;

    setMultiSpeed({
      speedItems,
    });
  };

  const handleReset = () => {
    form.resetFields();
    resetMultiSpeed();
  };

  return (
    <Form
      name="multiSpeedForm"
      layout="vertical"
      form={form}
      initialValues={{ speedItems: [] }}
      onReset={handleReset}
      onFinish={handleSubmit}
    >
      <Form.Item
        label={t("speedChart.speed.label")}
        rules={[
          {
            validator: async (_: any, names: any) => {
              if (!names || names.length < 2) {
                return Promise.reject(new Error(t("speedChart.speed.error2")));
              }
            },
          },
        ]}
      >
        <Form.List name="speedItems">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key}>
                  <Form.Item
                    {...field}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: t("speedChart.speed.error"),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("speedChart.speed.placeholder")}
                      style={{ width: "90%" }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    style={{ width: "10%" }}
                    onClick={() => remove(field.name)}
                  />
                </Form.Item>
              ))}
              <Form.Item noStyle>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{ width: "100%" }}
                >
                  {t("speedChart.speed.add")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item noStyle>
        <Button type="primary" htmlType="submit">
          {t("speedChart.speed.submit")}
        </Button>
        <Button type="ghost" style={{ marginLeft: 10 }} htmlType="reset">
          {t("speedChart.speed.clear")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MultiSpeedForm;
