import axios from "../libs/axios";

interface LoginData {
  account: string;
  password: string;
}

const login = async (user: LoginData) => await axios.post("/tokens/user", user);

const loginAsAdmin = async (admin: LoginData) =>
  await axios.post("/tokens/admin", admin);

const signout = async () => await axios.delete("/tokens");

const loginService = { login, loginAsAdmin, signout };

export default loginService;
