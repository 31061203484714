
import { useEffect, useState } from "react";
import { 
  PageHeader, 
  Button,
  Card,
  ConfigProvider,
  Table,
  Space,
  Form,
  Input,
  Menu,
  Dropdown,
  Modal,
  message
} from 'antd'
import { UnorderedListOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import BaseLayout from "../../components/BaseLayout";

import { langMap } from "../../i18n";
import supplierServices from '../../services/supplier';
import useLoading from "../../hooks/useLoading";
import "./index.css";

const SupplierList = () => {

  const { t, i18n } = useTranslation();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchRef] = Form.useForm();
  const [supplierID, setSupplierID] = useState("");
  const { confirm } = Modal;

  let query: string = searchParams.get("query") || "";
  let page: number = Number(searchParams.get("page")) || 1;
  let pageSize: number = Number(searchParams.get("pageSize")) || 20;
  const [total, setTotal] = useState<number>(0);
  const [infoVisible, setInfoVisible] = useState(false)
  const [editData] = Form.useForm()

  const columns = [
    { key: "id", title: "id", dataIndex: "id", width: 80 },
    { key: "name", title: t("supplierList.columns.name"), dataIndex: "name", ellipsis: true, width: 200 },
    { key: "code", title: t("supplierList.columns.code"), dataIndex: "code", ellipsis: true, width: 200 },
    { key: "date", title: t("supplierList.columns.date"), dataIndex: "createTime", ellipsis: true, width: 200 },
    {
      key: "action",
      title: t("supplierList.columns.action"),
      dataIndex: "action",
      width: 120,
      fixed: "right" as const,
      render: (_: any, record: any) => {
        const menu = (
          <Menu>
            <Menu.Item key="edit" onClick={() => onHandleEdit(record)}>{t("supplierList.action.edit")}</Menu.Item>
            <Menu.Item key="del" onClick={() => onHandleDel(record.id)}>{t("supplierList.action.delete")}</Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu}>
            <Button type="text" icon={<UnorderedListOutlined />}>
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    onPageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, pageSize])

  const handleSubmit = (values: { query: string }) => {
    const { query } = values;
    
    setSearchParams({ query, page: "1", pageSize: "20" });
  };

  const handleReset = () => {
    searchRef.resetFields();
    setSearchParams({});
  };

  const onHandleEdit = (item: any) => {
    setInfoVisible(true)
    setSupplierID(item === 'add'?'':item.id)
    editData.setFieldsValue({
      name: item === 'add'?'':item.name,
      code: item === 'add'?'':item.code
    })
  }

  const onEditFinished = async () => {
    let params = editData.getFieldsValue();
    try {
      if(supplierID) {
        params["id"] = supplierID
      }
      const res = await supplierServices.supplierInfoPut(params);
      const { response } = res.data;
      if(response) {
        setInfoVisible(false)
        onPageChange()
      }
    } catch (error) {
      message.error("Error")
    }
  }

  const onEditFinishFailed = () => {
    message.error("Error")
  }

  const onHandleDel = (id: any) => {
    confirm({
      title: t("supplierList.delete.warning"),
      icon: <ExclamationCircleOutlined />,
      content: t("supplierList.delete.content"),
      centered: true,
      onOk: async () => {
        try {
          const result = await supplierServices.supplierDel({supplierID: Number(id)});
          const { response } = result.data;
          if(response) {
            message.success(t("supplierList.delete.success"))
            onPageChange()
          }
        } catch (error) {
          message.error("Error")
        }
      },
      onCancel() {
        
      }
    });
  }

  const handlePageChange = (page: number, pageSize?: number) => {
    setSearchParams({
      query,
      page: String(page),
      pageSize: pageSize ? String(pageSize) : "20",
    });
  }

  const getSupplierList = async () => {
    try {
      const result = await supplierServices.getList({
        key: query,
        page: page,
        pageSize: pageSize,
      });
  
      const { response } = result.data
      if(response) {
        const { total, list } = response;
        setTotal(total);
        setDataSource(
          list.map((item: any) => ({
            id: item.id,
            createTime: item.create_time,
            code: item.code,
            name: item.name,
          }))
        );
      }
    } catch (error) {

    }
  }

  const [onPageChange, loading] = useLoading(getSupplierList);

  return (
    <BaseLayout title={t("route.supplierList")}>
      <PageHeader
        title={t("route.supplierList")}
        style={{ padding: "16px 0" }}
        extra={[
          <Button key="new" type="primary" onClick={() => onHandleEdit('add')}>
            {t("supplierList.new.title")}
          </Button>,
        ]}
      />

      <Card bodyStyle={{ paddingBottom: 0 }}>
        <Form layout="inline" onFinish={handleSubmit} form={searchRef}>
          <Form.Item name="query" style={{ paddingBottom: 24 }}>
            <Input placeholder={t("supplierList.search.name.placeholder")} />
          </Form.Item>
          <Form.Item style={{ paddingBottom: 24 }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={false}>
                {t("supplierList.search.submit")}
              </Button>
              <Button type="default" onClick={handleReset}>
                {t("supplierList.search.reset")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>

      <Card style={{ marginTop: 24 }}>
        <ConfigProvider locale={langMap(i18n.language)}>
          <Table
            columns={columns}
            rowKey={record=>record.id}
            bordered={false}
            dataSource={dataSource}
            scroll={{ x: 325 }}
            pagination={{
              current: page,
              pageSize,
              total,
              onChange: handlePageChange,
            }}
            loading={loading}
          />
        </ConfigProvider>
      </Card>
      <Modal centered open={infoVisible} onCancel={() => setInfoVisible(false)} footer={null}>
        <h3 className="edit-title">{supplierID?t("supplierList.action.edit"):t("supplierList.action.add")}</h3>
        <Form
          name="basic"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          form={editData}
          onFinish={onEditFinished}
          onFinishFailed={onEditFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t("supplierList.form.name.title")}
            name="name"
            getValueFromEvent={(e)=>{ return e.target.value.replace(/(^\s*)|(\s*$)/g, ''); }}
            rules={[{ required: true, message: t("supplierList.form.name.message") }]}
          >
            <Input placeholder={t("supplierList.form.placeholder")} />
          </Form.Item>
          <Form.Item
            label={t("supplierList.form.code.title")}
            name="code"
            getValueFromEvent={(e)=>{ return e.target.value.replace(/(^\s*)|(\s*$)/g, ''); }}
            rules={[{ required: true, message: t("supplierList.form.code.message") }]}
          >
            <Input placeholder={t("supplierList.form.placeholder")} />
          </Form.Item>
           <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button className="edit-btn-cancel" onClick={() => setInfoVisible(false)}>{t("supplierList.form.cancel")}</Button>
            <Button type="primary" htmlType="submit">{t("supplierList.form.submit")}</Button>
          </Form.Item>
        </Form>
      </Modal>
    </BaseLayout>
  )
}

export default SupplierList;