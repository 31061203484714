import { Button, Drawer, Table } from "antd";
import { useTranslation } from "react-i18next";
import useSpuPoints from "../../../stores/spuPoints";
import useDrawer from "../../../hooks/useDrawer";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const columns = [
  {
    key: "q",
    title: "Q",
    dataIndex: "q",
    ellipsis: true,
  },
  {
    key: "h",
    title: "H",
    dataIndex: "h",
    ellipsis: true,
  },
  {
    key: "eff",
    title: "EFF",
    dataIndex: "eff",
    ellipsis: true,
  },
  {
    key: "npsh",
    title: "NPSHr",
    dataIndex: "npsh",
    ellipsis: true,
  },
];

const View = () => {
  const { t } = useTranslation();
  const { xs } = useBreakpoint();
  const { dataSource } = useSpuPoints();
  const { visible, show, hide } = useDrawer();

  const drawerWidth = xs ? 378 : 520;

  return (
    <>
      <Button type="default" onClick={show}>
        {t("spuChart.tools.view")}
      </Button>
      <Drawer
        title={t("spuChart.tools.view")}
        onClose={hide}
        visible={visible}
        destroyOnClose
        width={drawerWidth}
      >
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </Drawer>
    </>
  );
};

export default View;
