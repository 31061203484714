/* eslint-disable react-hooks/exhaustive-deps */
import Chart, { EChartsOption } from "../../../components/Chart";
import { useTranslation } from "react-i18next";
import useSpuPoints, {
  PerformanceFormField,
  SpuPointsDataset,
  SpuPointsInfo,
} from "../../../stores/spuPoints";
import { useEffect, useState } from "react";
import {
  parseSpuPointsDataset,
  calcYAxis,
  findIntersectionPoint,
  createAuxiliaryData,
  createK,
  createQH2Data,
  createQEFF2Data,
  createQNPSH2Data,
  createQP,
  createDiameterData,
  createSpeedData,
  createFrequencyData,
  convertEFF1ToEFF2,
  powerFormula,
  precision,
} from "../../../utils";
import { LineSeriesOption } from "echarts/charts";
import { genRenderItem, renderLabel, exportTemplate } from "../../../libs/echar";
import { EChartsType } from "echarts/core";
import { DataPoint } from "regression";
import useSpuPdfParam from "../../../stores/spuPdfParam";

// 性能与多速度

interface PerformanceSpeedChartProps {
  name: string;
  info: SpuPointsInfo;
  dataset: SpuPointsDataset;
  performance: PerformanceFormField;
  multiSpeed: number[];
}

const PerformanceSpeedChart = ({
  name,
  info,
  dataset,
  performance,
  multiSpeed,
}: PerformanceSpeedChartProps) => {
  const { t } = useTranslation();
  const { setPerformanceSpeedResult } = useSpuPoints();

  const initialOption: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      feature: {
        saveAsImage: { name: name, pixelRatio: 2 },
      },
    },
    grid: [{ bottom: "40%" }, { top: "70%" }],
    xAxis: [
      // Q1
      { name: "m³/h", type: "value" },
      // Q2
      { name: "m³/h", type: "value", gridIndex: 1 },
    ],
  };

  const [option, setOption] = useState(initialOption);

  useEffect(() => {
    // 对原始数据进行线性回归
    const { QH, QP, QEFF, QNPSH } = parseSpuPointsDataset(dataset);
    const { quantity, head, sg, type } = performance;

    // 找到共有区间
    const [hMax, hInterval] = calcYAxis(
      QH.dummyPoints.map((p) => p[1]),
      10,
      1
    );
    const [pMax, pInterval] = calcYAxis(
      QP.map((p) => p[1]),
      5,
      1
    );

    // 创建 PerformanceSpeedChart 视图
    const initialSeries: LineSeriesOption[] = [
      {
        name: "QH1",
        type: "line",
        label: renderLabel("Q-H"),
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          width: 1,
        },
        data: QH.dummyPoints,
        color: "black",
        animation: false,
      },
      {
        name: "QEFF",
        type: "line",
        label: renderLabel("Q-EFF"),
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          width: 1,
        },
        data: QEFF.dummyPoints,
        color: "black",
        xAxisIndex: 0,
        yAxisIndex: 1,
        animation: false,
      },
      {
        name: "QP1",
        type: "line",
        label: renderLabel("Q-P"),
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          width: 1,
        },
        data: QP,
        color: "black",
        xAxisIndex: 1,
        yAxisIndex: 2,
        animation: false,
      },
      {
        name: "QNPSH",
        type: "line",
        label: renderLabel("Q-NPSHr"),
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          width: 1,
        },
        data: QNPSH ? QNPSH.dummyPoints : [],
        color: "black",
        xAxisIndex: 1,
        yAxisIndex: 3,
        animation: false,
      },
    ];

    const { speed } = info;
    const speedSeries: LineSeriesOption[] = multiSpeed.map(
      (newSpeed: number, index: number) => {
        const k = newSpeed / speed;
        const data = QH.dummyPoints.map((p) => [p[0] * k, p[1] * k ** 2]);

        return {
          id: index + 1,
          name: `Speed ${index + 1}`,
          type: "line",
          label: renderLabel(`${newSpeed} RPM`),
          symbolSize: 0,
          data,
          animation: false,
        };
      }
    );

    let newOption: EChartsOption = {
      ...initialOption,
      yAxis: [
        // H
        {
          name: "H(m)",
          type: "value",
          min: 0,
          max: hMax,
          interval: hInterval,
        },
        // EFF
        {
          name: "EFF(%)",
          type: "value",
          min: 0,
          max: 200,
          splitNumber: 10,
          axisLabel: {
            formatter: (v: any) => {
              if (v <= 100) {
                return v;
              }

              return '';
            }
          }
        },
        // P
        {
          name: "P(kW)",
          type: "value",
          min: 0,
          max: pMax,
          interval: pInterval,
          gridIndex: 1,
        },
        // NPSHr
        {
          name: "NPSHr(m)",
          type: "value",
          gridIndex: 1,
          min: 0,
          max: 15,
          splitNumber: 5,
        },
      ],
      series: [...initialSeries, ...speedSeries],
    };

    // 如果表单数据，则绘制 QH2/QP2/QEFF2/QNPSH2 和对应文字
    if (quantity !== undefined && head !== undefined) {
      const { range } = info;
      // 获取区间中点
      const center = (range.start + range.end) / 2;
      // 获取辅助线与QH1的交点
      const intersectionPoint = findIntersectionPoint(
        quantity,
        head,
        QH.equation,
        type,
        center
      );
      if (intersectionPoint) {
        // 生成辅助线数据
        const auxData = createAuxiliaryData(
          quantity,
          head,
          0,
          intersectionPoint[0],
          type
        );

        // 根据辅助线生成 QH2
        const k: number = createK(quantity, intersectionPoint[0], type);
        const qH2Data = createQH2Data(k, QH.dummyPoints, type);

        // 平移 QEFF
        const qEFF2Data = createQEFF2Data(k, QEFF.dummyPoints, type);

        // 平移 QNPSH
        const qNPSH2Data = createQNPSH2Data(
          k,
          QNPSH ? QNPSH.dummyPoints : [],
          type
        );

        // 平移 QP
        const qH2Points = createQH2Data(k, QH.points as DataPoint[], type);
        const qEFF2Points = createQEFF2Data(
          k,
          QEFF.points as DataPoint[],
          type
        );
        const qP2Data = createQP(qH2Points, qEFF2Points, sg || 1);

        // 计算叶轮尺寸
        const { diameter, diameterUnit } = createDiameterData(
          k,
          info.diameter,
          type
        );

        // 计算转速
        const speed = createSpeedData(k, info.speed, type);

        // 计算频率
        const frequency = createFrequencyData(k, info.frequency, type);

        // EFF
        const eff = convertEFF1ToEFF2(QEFF, intersectionPoint[0], k);

        // P
        const power = powerFormula([quantity, head], eff / 100) * (sg || 1);

        // NPSH
        const npsh = QNPSH ? QNPSH.predict(quantity)[1] : undefined;

        // 更新表单
        setPerformanceSpeedResult({
          diameter,
          speed,
          frequency,
          power,
          npsh,
          eff,
        });

        // 更新图表
        newOption = {
          ...newOption,
          series: [
            ...initialSeries,
            ...speedSeries,
            {
              name: "aux",
              type: "line",
              smooth: true,
              symbolSize: 0,
              lineStyle: { width: 1 },
              color: "#aaa",
              data: auxData,
              animation: false,
            },
            {
              name: "QH2",
              type: "line",
              smooth: true,
              symbolSize: 0,
              color: "#aaa",
              label: renderLabel(
                type === 0
                  ? `${precision(speed, 0)} RPM`
                  : `${precision(diameter, 0)} ${diameterUnit}`
              ),
              data: qH2Data,
              animation: false,
            },
            {
              name: "QEFF2",
              type: "line",
              smooth: true,
              symbolSize: 0,
              lineStyle: { width: 1 },
              xAxisIndex: 0,
              yAxisIndex: 1,
              color: "#aaa",
              data: qEFF2Data,
              animation: false,
            },
            {
              name: "QNPSH2",
              type: "line",
              smooth: true,
              symbolSize: 0,
              lineStyle: { width: 1 },
              xAxisIndex: 1,
              yAxisIndex: 3,
              color: "#aaa",
              data: qNPSH2Data,
              animation: false,
            },
            {
              name: "QP2",
              type: "line",
              smooth: true,
              symbolSize: 0,
              lineStyle: { width: 1 },
              xAxisIndex: 1,
              yAxisIndex: 2,
              color: "#aaa",
              data: qP2Data,
              animation: false,
            },
            {
              name: "intersection point",
              type: "custom",
              renderItem: genRenderItem("circle", quantity, head),
              data: [
                {
                  name: "intersection point",
                  xAxis: quantity,
                  yAxis: head,
                },
              ],
              z: 100,
            },
            {
              name: "top info",
              type: "custom",
              renderItem: genRenderItem("text", 330, 65, [
                { label: "Q", value: precision(quantity, 1), unit: "m³/h" },
                { label: "H", value: precision(head, 1), unit: "m" },
                {
                  label: t("chart.diameter"),
                  value: precision(diameter, 0),
                  unit: diameterUnit,
                },
                {
                  label: t("chart.speed"),
                  value: precision(speed, 0),
                  unit: "rpm",
                },
                {
                  label: t("chart.efficiency"),
                  value: precision(eff, 2),
                  unit: "%",
                },
                {
                  label: t("chart.sg"),
                  value: precision(sg || 1, 2),
                },
              ]),
              data: [{ x: 0, y: 0 }],
              z: 100,
            },
            {
              name: "bottom info",
              type: "custom",
              renderItem: genRenderItem("text", 365, 495, [
                { label: "P", value: precision(power, 2), unit: "kw" },
                {
                  label: "NPSHr",
                  value: npsh ? precision(npsh, 2) : npsh,
                  unit: "m",
                },
              ]),
              data: [{ x: 0, y: 0 }],
              z: 100,
            },
          ],
        };
      }
    }

    setOption(newOption);
  }, [
    JSON.stringify(performance),
    JSON.stringify(multiSpeed),
    JSON.stringify(dataset),
  ]);

  const { setPerformanceSpeedImage } = useSpuPdfParam();

  const handleChartChange = (instance: EChartsType) => {
    const result = instance.getDataURL(exportTemplate as any);
    // 当 excludeComponents 执行后，会导致图表上对应的组件暂时消失。
    // 这里强制执行一次，重新让组件显示出来.
    instance.getDataURL();
    setPerformanceSpeedImage(result);
  };

  return (
    <Chart
      option={option}
      notMerge
      style={{ width: 500, height: 700, margin: "auto" }}
      onChange={handleChartChange}
    />
  );
};

export default PerformanceSpeedChart;
